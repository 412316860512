import { Slot } from "@radix-ui/react-slot";
import { HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

// This component is used to redact content in Fullstory recordings.
// We configure Fullstory to select all elements with the class "fs-redacted" and ignore them.

interface Props extends HTMLAttributes<HTMLElement> {
    asChild?: boolean;
}

export const FullstoryRedacted = ({
    asChild = false,
    className,
    ...props
}: Props) => {
    const Comp = asChild ? Slot : "div";
    return <Comp className={cn("fs-redacted", className)} {...props} />;
};
