import {
    CreditCard,
    Loader2,
    SquareMousePointer,
    Paintbrush,
    Settings,
    Shield,
    User,
    Users,
    Info,
} from "lucide-react";
import { PropsWithChildren, Suspense } from "react";
import { NavLink, Outlet } from "react-router-dom";

import { AuthLevel } from "@/api/types";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { AuthGate } from "@/gates/auth-gate";
import { useUser } from "@/hooks/use-user";
import { useV3 } from "@/hooks/use-v3";
import { canSeeBillingSettings, canSeeManageUsersSettings } from "@/utils/user";

const SettingsLink = (props: PropsWithChildren<{ to: string }>) => (
    <Button variant="ghost" className="justify-start" asChild>
        <NavLink to={props.to} className="aria-[current=page]:bg-accent">
            {props.children}
        </NavLink>
    </Button>
);

export const SettingsLayout = () => {
    const user = useUser();
    const [v3] = useV3();
    const getUrl = (to: string): string => (v3 ? `/v3${to}` : to);

    return (
        <div className="container space-y-10 py-10 md:grid md:grid-cols-3 md:gap-10 md:space-y-0 lg:grid-cols-4 xl:grid-cols-5">
            <h1 className="font-headline col-span-full text-3xl font-medium md:px-4">
                Settings
            </h1>
            <div className="-mx-4 flex flex-col items-stretch gap-2 md:mx-0">
                <SettingsLink to={getUrl("/settings/profile")}>
                    <User className="mr-2 size-4 shrink-0" />
                    Profile
                </SettingsLink>
                <SettingsLink to={getUrl("/settings/password")}>
                    <Shield className="mr-2 size-4 shrink-0" />
                    Password
                </SettingsLink>
                <SettingsLink to={getUrl("/settings/appearance")}>
                    <Paintbrush className="mr-2 size-4 shrink-0" />
                    Appearance
                </SettingsLink>
                <SettingsLink to={getUrl("/settings/about")}>
                    <Info className="mr-2 size-4 shrink-0" />
                    About
                </SettingsLink>
                {canSeeBillingSettings(user) && (
                    <SettingsLink to={getUrl("/settings/billing")}>
                        <CreditCard className="mr-2 size-4 shrink-0" />
                        Billing
                    </SettingsLink>
                )}
                <AuthGate predicate={canSeeManageUsersSettings}>
                    <SettingsLink to={getUrl("/settings/manage-users")}>
                        <Users className="mr-2 size-4 shrink-0" />
                        Manage Users
                    </SettingsLink>
                </AuthGate>
                <AuthGate min={AuthLevel.superadmin}>
                    <div className="flex min-h-10 items-center gap-2">
                        <p className="text-muted-foreground text-xs font-semibold uppercase">
                            Internal
                        </p>
                        <Separator className="w-auto grow" />
                    </div>
                    <SettingsLink to={getUrl("/settings/dev")}>
                        <Settings className="mr-2 size-4 shrink-0" />
                        Dev Settings
                    </SettingsLink>
                    <SettingsLink to={getUrl("/settings/nux")}>
                        <SquareMousePointer className="mr-2 size-4 shrink-0" />
                        NUX
                    </SettingsLink>
                </AuthGate>
            </div>
            <Suspense
                fallback={
                    <div className="col-span-2 flex justify-center p-10 lg:col-span-3 xl:col-span-4">
                        <Loader2 className="size-4 animate-spin" />
                    </div>
                }
            >
                <Outlet />
            </Suspense>
        </div>
    );
};
