import { FeatureFlagBool, FeatureFlagNumber } from "@/conf/feature-flags";
import { useConfig } from "@/hooks/use-config";

export const useFeatureFlagBool = (name: FeatureFlagBool): boolean => {
    const { featureFlags } = useConfig();
    return featureFlags[name];
};

export const useFeatureFlagNumber = (name: FeatureFlagNumber): number => {
    const { featureFlags } = useConfig();
    return featureFlags[name];
};
