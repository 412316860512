import { ProbeBase } from "@/api/rest";
import { ColumnForm } from "@/components/document-table/column-form";
import { useColumnSheetContext } from "@/components/document-table/columns/use-column-sheet";
import { useAsyncState } from "@/hooks/use-async-state";

type Props = {
    onSuccess?: () => void;
};

export const CreateColumn = (props: Props) => {
    const createColumn = useColumnSheetContext((s) => s.onCreate);
    const createAction = useAsyncState(
        async (probe: ProbeBase) => await createColumn(probe),
        { onSuccess: props.onSuccess },
    );
    return <ColumnForm action={createAction} />;
};
