import { NavLink, Outlet } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { useV3 } from "@/hooks/use-v3";
import { cn } from "@/lib/utils";

export const AdminLayout = () => {
    const [v3] = useV3();
    const getUrl = (to: string): string => (v3 ? `/v3${to}` : to);
    return (
        <div className={cn("container", v3 && "relative grow overflow-scroll")}>
            <div className="-mx-4 my-4">
                <NavLink to={getUrl("/admin/dashboard")}>
                    {({ isActive }) => (
                        <Button variant={isActive ? "secondary" : "link"}>
                            Dashboard
                        </Button>
                    )}
                </NavLink>
                <NavLink to={getUrl("/admin/accounts")}>
                    {({ isActive }) => (
                        <Button variant={isActive ? "secondary" : "link"}>
                            Accounts
                        </Button>
                    )}
                </NavLink>
                <NavLink to={getUrl("/admin/chat-messages")}>
                    {({ isActive }) => (
                        <Button variant={isActive ? "secondary" : "link"}>
                            Chat Messages
                        </Button>
                    )}
                </NavLink>
                <NavLink to={getUrl("/admin/markdown")}>
                    {({ isActive }) => (
                        <Button variant={isActive ? "secondary" : "link"}>
                            Markdown
                        </Button>
                    )}
                </NavLink>
            </div>
            <Outlet />
        </div>
    );
};
