import { PropsWithChildren } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AppSidebar } from "@/components/app-sidebar";
import { OfflineIndicator } from "@/components/offline-indicator";
import { PageBreadcrumb } from "@/components/page-breadcrumb";
import { Separator } from "@/components/ui/separator";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { useV3 } from "@/hooks/use-v3";

export default function SidebarAppLayout({ children }: PropsWithChildren) {
    const [v3] = useV3();
    const location = useLocation();
    if (!v3 && location.pathname.startsWith("/v3/")) {
        return <Navigate to={location.pathname.substring(3)} />;
    }
    return (
        <SidebarProvider>
            <AppSidebar />
            <OfflineIndicator />
            <section className="flex h-screen grow flex-col overflow-hidden">
                <header className="flex h-16 shrink-0 items-center gap-2 border-b transition-[width,height] duration-100 ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
                    <div className="flex items-center gap-2 px-4">
                        <SidebarTrigger className="-ml-1" />
                        <Separator
                            orientation="vertical"
                            className="mr-2 h-4"
                        />
                        <PageBreadcrumb />
                    </div>
                </header>
                {children ?? <Outlet />}
            </section>
        </SidebarProvider>
    );
}
