import { RefreshCw } from "lucide-react";
import { useShallow } from "zustand/react/shallow";

import { FindingGroupType } from "@/api/types";
import { AsyncButtonWithTooltip } from "@/components/async-button-with-tooltip";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useAnalyze } from "@/hooks/use-analyze";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { useGridView } from "@/hooks/use-grid-view-context";

export const RegenerateSynthesisButton = ({
    synthesis_type,
}: {
    synthesis_type: FindingGroupType;
}) => {
    const api = useApi();
    const reportID = useGridView((s) => s.report.id);
    const finding_ids = useAnalyze(
        useShallow((s) => s.synthesis?.finding_ids ?? []),
    );
    const updateSynthesisInfo = useAnalyze((s) => s.updateSynthesisInfo);
    const canRegenerateSynthesis = useFeatureFlagBool(
        FeatureFlagBool.REGENERATE_SYNTHESIS,
    );
    const regenAction = useAsyncState(
        async () =>
            await api.create_synthesis(reportID, finding_ids, synthesis_type, {
                force: true,
            }),
        { onSuccess: updateSynthesisInfo },
    );

    if (!canRegenerateSynthesis) return null;

    return (
        <AsyncButtonWithTooltip
            tooltip="Regenerate Synthesis"
            action={regenAction}
            variant="ghost"
            size="icon"
        >
            <RefreshCw className="size-4" />
        </AsyncButtonWithTooltip>
    );
};
