import { ClipboardListIcon, LucideProps } from "lucide-react";
import { Link, NavLink, useMatch } from "react-router-dom";

import { BrightwaveLogo } from "@/components/brightwave-logo";
import { NavUser } from "@/components/nav-user";
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupContent,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarRail,
    useSidebar,
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";

type Item = {
    title: string;
    url: string;
    icon: React.ForwardRefExoticComponent<
        Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>
    >;
};

const items: Item[] = [
    {
        title: "Projects",
        url: "/v3",
        icon: ClipboardListIcon,
    },
];

const SidebarMenuNavLink = ({ item }: { item: Item }) => {
    const isActive = useMatch(item.url) !== null;
    return (
        <SidebarMenuItem>
            <SidebarMenuButton isActive={isActive} tooltip={item.title} asChild>
                <NavLink to={item.url}>
                    <item.icon />
                    <span>{item.title}</span>
                </NavLink>
            </SidebarMenuButton>
        </SidebarMenuItem>
    );
};

export function AppSidebar() {
    const { state } = useSidebar();
    return (
        <Sidebar collapsible="icon">
            <SidebarHeader
                className={cn(
                    "p-3 px-5",
                    state === "collapsed" && "-translate-x-px",
                )}
            >
                <SidebarMenu>
                    <SidebarMenuItem className="py-2">
                        <Link
                            to="/v3"
                            className="text-foreground transition-opacity hover:opacity-80"
                        >
                            <BrightwaveLogo
                                hideWordmark={state === "collapsed"}
                            />
                        </Link>
                    </SidebarMenuItem>
                </SidebarMenu>
            </SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupContent className="px-2">
                        <SidebarMenu>
                            {items.map((item) => (
                                <SidebarMenuNavLink
                                    key={item.title}
                                    item={item}
                                />
                            ))}
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>
            <SidebarFooter className="px-4">
                <NavUser />
            </SidebarFooter>
            <SidebarRail />
        </Sidebar>
    );
}
