import { AlertCircle, ChevronDown, Reply } from "lucide-react";
import { HTMLAttributes } from "react";

import {
    APActionStatus,
    APActionType,
    APActionBase,
    APActionStatusReason,
    Exchange,
} from "@/api/types";
import { ActionActions } from "@/components/action-message/action-actions";
import { ActionHeader } from "@/components/action-message/action-header";
import {
    ActionMessageNoEvidence,
    Evidence,
} from "@/components/action-message/evidence";
import { IntentConfirmationForm } from "@/components/action-message/intent-confirmation-form";
import { MessageCard } from "@/components/action-message/message-card";
import {
    ActionMessageResponseSkeleton,
    FollowUpQuestionSkeleton,
} from "@/components/action-message/skeletons";
import { BrightwaveLogo } from "@/components/brightwave-logo";
import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { Citation } from "@/components/citation";
import { Debugger } from "@/components/debug/debugger";
import { FullstoryRedacted } from "@/components/fullstory-redacted";
import { Markdown } from "@/components/markdown";
import { CitationContextProvider } from "@/context/citation-context-provider";
import { useActionsContext } from "@/hooks/use-actions-context";
import { cn } from "@/lib/utils";
import {
    getActionLabel,
    getActionState,
    getActionStatusReason,
    getCitations,
    getEvidence,
    // getQuote,
    getResponse,
} from "@/utils/actions";
import {
    CHARS,
    isEmptyOrNull,
    isString,
    simpleHash,
} from "@/utils/string-helpers";

interface Props extends HTMLAttributes<HTMLDivElement> {
    action: APActionBase;
}

const Response = ({
    action,
    withLoader,
    className,
}: Props & { withLoader?: boolean }) => {
    const isLoading = action.status === APActionStatus.pending;
    const response = getResponse(action);
    if (withLoader && isLoading && isEmptyOrNull(response?.content)) {
        return (
            <ActionMessageResponseSkeleton
                className={cn("mt-1 basis-7/12", className)}
            />
        );
    }
    if (response === undefined) return null;

    return (
        <CitationContextProvider
            citations={getCitations(action)}
            documents={getEvidence(action)?.map((e) => e.document_info)}
        >
            <FullstoryRedacted asChild>
                <Markdown className={cn("py-4", className)} useCustomTable>
                    {response.content}
                </Markdown>
            </FullstoryRedacted>
            <Citation />
        </CitationContextProvider>
    );
};

const Error = ({ action }: { action: APActionBase }) => {
    switch (action.status) {
        case APActionStatus.failed:
        case APActionStatus.error:
            switch (action.status_reason) {
                case APActionStatusReason.no_evidence_found:
                    return <ActionMessageNoEvidence />;
                case APActionStatusReason.invalid_selection:
                    return (
                        <div className="flex items-start gap-3 text-amber-500">
                            <AlertCircle className="mt-1 size-4 shrink-0" />
                            <p>
                                Could not find any information for the
                                highlighted text. Try a different selection.
                            </p>
                        </div>
                    );
                default:
                    return (
                        <div className="text-destructive flex items-start gap-3">
                            <AlertCircle className="mt-1 size-4 shrink-0" />
                            <p>
                                Sorry, something went wrong. Please try again.
                            </p>
                            <Debugger>
                                <p className="italic">
                                    Reason: {action.status_reason ?? "unknown"}
                                </p>
                            </Debugger>
                        </div>
                    );
            }
        default:
            return null;
    }
};

const ActionResponsePreview = ({ action }: { action: APActionBase }) => {
    const actions = useActionsContext((s) => s.actions);
    const response_message = isString(action.response_to)
        ? actions.get(action.response_to)
        : action.response_to;
    if (response_message == null) return null;
    return (
        <div className="bg-muted space-y-1 rounded p-2">
            <div className="flex items-center gap-1">
                <Reply className="size-4 shrink-0" />
                <p className="font-headline text-xs font-bold">Reply to</p>
            </div>
            <div className="bg-background rounded p-2 text-sm">
                <p className="font-headline font-bold">
                    {getActionLabel(response_message.type)}
                </p>
                <p className="line-clamp-1">{response_message.content}</p>
            </div>
        </div>
    );
};

const ActionStatus = ({ action }: { action: APActionBase }) => {
    const statusReason = action.status_reason;
    if (
        !statusReason ||
        statusReason === APActionStatusReason.generating_response
    ) {
        return null;
    }
    const isError =
        action.status === APActionStatus.error ||
        action.status === APActionStatus.failed;
    return (
        <div
            className={cn(
                "flex items-center gap-1.5 text-sm",
                isError && "text-destructive",
            )}
        >
            <BrightwaveLogo hideWordmark className="size-4" />
            <span>
                {getActionStatusReason(statusReason)}
                {action.status === APActionStatus.pending
                    ? CHARS.ellipsis
                    : undefined}
            </span>
        </div>
    );
};

const ActionConfirmation = ({ action }: { action: APActionBase }) => {
    const state = getActionState(action);
    const companies = state?.company_search_result?.companies.map((c) => ({
        symbol: c.ticker,
        name: c.info.name,
        exchange: Exchange.NASDAQ,
    }));

    if (
        action.status === APActionStatus.pending &&
        action.status_reason === APActionStatusReason.searched_companies
    ) {
        return (
            <IntentConfirmationForm
                action_id={action.id}
                defaultCompanies={companies}
                timeframe={state?.query_analysis?.timeframe_analysis}
            />
        );
    }
    return null;
};

const ActionInput = ({
    children,
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => {
    const child = isString(children)
        ? children
              .split("\n\n")
              .map((s, i) => <p key={simpleHash(`${i}_${s}`)}>{s}</p>)
        : children;
    return (
        <FullstoryRedacted
            className={cn(
                "bg-muted ml-24 space-y-2 rounded p-4 font-semibold",
                className,
            )}
            {...props}
        >
            {child}
        </FullstoryRedacted>
    );
};

export const ActionMessage = ({ action, ...props }: Props) => {
    const collapse = useActionsContext((s) => s.collapseAction);
    const header = (
        <ActionHeader
            action={action}
            className="-mx-2 -mt-2"
            icon={
                <ButtonWithTooltip
                    variant="ghost"
                    size="icon-sm"
                    onClick={() => collapse(action.id)}
                    tooltip="Collapse"
                    className="group"
                >
                    <ChevronDown className="size-4 transition-transform group-hover:-rotate-180" />
                </ButtonWithTooltip>
            }
        />
    );
    switch (action.type) {
        case APActionType.chat_message:
            return (
                <MessageCard {...props}>
                    {header}
                    <ActionResponsePreview action={action} />
                    <ActionInput>{action.content}</ActionInput>
                    <ActionStatus action={action} />
                    <Response action={action} />
                    <Evidence action={action} />
                    <Error action={action} />
                    <ActionActions action={action} />
                </MessageCard>
            );
        case APActionType.search:
            return (
                <MessageCard {...props}>
                    {header}
                    <ActionResponsePreview action={action} />
                    <ActionInput>{action.content}</ActionInput>
                    <ActionStatus action={action} />
                    <ActionConfirmation action={action} />
                    <Response action={action} withLoader />
                    <Evidence action={action} />
                    <Error action={action} />
                    <ActionActions action={action} />
                </MessageCard>
            );
        case APActionType.show_evidence:
            return (
                <MessageCard {...props}>
                    {header}
                    <ActionResponsePreview action={action} />
                    <ActionStatus action={action} />
                    <Response action={action} />
                    <Evidence action={action} />
                    <Error action={action} />
                    <ActionActions action={action} />
                </MessageCard>
            );
        case APActionType.follow_up_questions:
            return (
                <MessageCard {...props}>
                    {header}
                    <ActionResponsePreview action={action} />
                    <ActionInput className="italic">
                        {action.content}
                    </ActionInput>
                    <ActionStatus action={action} />
                    <Response
                        action={action}
                        className="prose-ul:divide-y prose-li:pt-2"
                    />
                    {action.status === APActionStatus.pending && (
                        <FollowUpQuestionSkeleton className="ml-8" />
                    )}
                    <Error action={action} />
                </MessageCard>
            );
        case APActionType.tell_me_more:
        case APActionType.market_commentary:
        case APActionType.implications:
        case APActionType.thesis_generation:
            return (
                <MessageCard {...props}>
                    {header}
                    <ActionResponsePreview action={action} />
                    <ActionInput className="italic">
                        {action.content}
                    </ActionInput>
                    <ActionStatus action={action} />
                    <Response action={action} />
                    <Evidence action={action} />
                    <Error action={action} />
                    <ActionActions action={action} />
                </MessageCard>
            );
        default:
            return action.type satisfies never;
    }
};
