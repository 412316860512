import { Loader2, X } from "lucide-react";
import { PropsWithChildren, Suspense } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { validate as validateUUID } from "uuid";
import { useShallow } from "zustand/react/shallow";

import { ProbeBase } from "@/api/rest";
import { ContextType, Probe, UploadFile, UUID } from "@/api/types";
import { ActionPanel } from "@/components/action-panel/action-panel";
import { ActionPanelHeader } from "@/components/action-panel/header";
import { OverlayContainer, OverlayContent } from "@/components/analyze/overlay";
import { GridViewContentSkeleton } from "@/components/analyze/skeleton";
import { DocumentLinkContext } from "@/components/document/document-link-context";
import { ColumnSheet } from "@/components/document-table/columns/sheet";
import { LeftPanelHeader } from "@/components/document-table/left-panel-header";
import { ReportContent } from "@/components/document-table/report/content";
import { RegenerateSynthesisButton } from "@/components/document-table/synthesis/regenerate-button";
import { SynthesisDetails } from "@/components/document-table/synthesis/synthesis-details";
import { RestoreScrollPosition } from "@/components/restore-scroll-position";
import { Button } from "@/components/ui/button";
import { FeatureFlagBool, FeatureFlagNumber } from "@/conf/feature-flags";
import { DocumentContainer } from "@/container/grid-view-document-container";
import { GridView } from "@/container/gridview";
import { ProjectContainer } from "@/container/project";
import { ActionsContextProvider } from "@/context/actions-context-provider";
import { ContextContextProvider } from "@/context/context-context-provider";
import { FileUploadContextProvider } from "@/context/file-upload-context-provider";
import { GridViewContextProvider } from "@/context/grid-view-context-provider";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useAnalyze } from "@/hooks/use-analyze";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import {
    useFeatureFlagBool,
    useFeatureFlagNumber,
} from "@/hooks/use-feature-flag";
import { useGridView } from "@/hooks/use-grid-view-context";
import { useProject } from "@/hooks/use-project";
import { NotFoundRoute } from "@/routes/not-found";
import { isFileUploaded } from "@/utils/file-upload";

export const Overlay = () => {
    const chatEnabled = useFeatureFlagBool(FeatureFlagBool.CHAT_ENABLED);

    const isActionPanelOpen = useActionsContext((s) => s.isActionPanelOpen);
    const openActionPanel = useActionsContext((s) => s.actionPanelOpen);
    const closeActionPanel = useActionsContext((s) => s.actionPanelClose);

    const selectedDocumentID = useAnalyze((s) => s.document_id);
    const selectedSynthesis = useAnalyze((s) => s.synthesis);
    const isReportOpen = useAnalyze((s) => s.report ?? false);
    const clearLeftPanel = useAnalyze((s) => s.clear);

    const dismissOverlay = () => {
        closeActionPanel();
        clearLeftPanel();
    };

    useHotkeys(
        "mod+j",
        () => (isActionPanelOpen ? closeActionPanel() : openActionPanel()),
        { enableOnFormTags: true },
    );
    useHotkeys("esc", dismissOverlay, { enableOnFormTags: true });

    return (
        <OverlayContainer onDismiss={dismissOverlay}>
            {chatEnabled && (
                <OverlayContent side="left" open={isActionPanelOpen}>
                    <ActionPanel
                        header={
                            <ActionPanelHeader
                                title="Actions"
                                onClose={closeActionPanel}
                            />
                        }
                        className="flex w-full flex-col *:pb-2"
                        disableInquiry
                    />
                </OverlayContent>
            )}
            <OverlayContent
                side="right"
                open={
                    selectedDocumentID != undefined ||
                    selectedSynthesis !== undefined ||
                    isReportOpen
                }
            >
                <div className="bg-background flex min-h-12 items-center justify-between gap-2 border-b p-1">
                    <LeftPanelHeader className="grow px-3" />
                    {selectedSynthesis != undefined && (
                        <RegenerateSynthesisButton
                            synthesis_type={selectedSynthesis.info.type}
                        />
                    )}
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={clearLeftPanel}
                    >
                        <X className="size-4" />
                    </Button>
                </div>
                <Suspense
                    fallback={
                        <div className="flex justify-center p-20">
                            <Loader2 className="size-4 animate-spin" />
                        </div>
                    }
                >
                    {selectedDocumentID != undefined && (
                        <DocumentContainer documentID={selectedDocumentID} />
                    )}
                    {selectedSynthesis != undefined && (
                        <RestoreScrollPosition
                            storageKey={selectedSynthesis.info.id}
                        >
                            <SynthesisDetails
                                findingGroupInfo={selectedSynthesis.info}
                            />
                        </RestoreScrollPosition>
                    )}
                    {isReportOpen && <ReportContent />}
                </Suspense>
            </OverlayContent>
        </OverlayContainer>
    );
};

export const GridViewColumnSheet = () => {
    const addedProbeIDs = useGridView(
        useShallow((s) => new Set(s.columns.map((c) => c.details.id))),
    );
    const addColumn = useGridView((s) => s.addColumn);
    const createColumn = useGridView((s) => s.createColumn);
    const updateColumn = useGridView((s) => s.updateColumn);
    const setColumnStar = useGridView((s) => s.setColumnStar);

    const selectAction = useAsyncState(
        async (probe: Probe) => {
            await addColumn(probe.id);
            return probe;
        },
        {
            onSuccess: (probe) =>
                toast.success(`Analysis "${probe.name}" added`),
            onError: () => toast.error("Something went wrong."),
        },
    );
    const createAction = useAsyncState(
        async (probe: ProbeBase) => {
            await createColumn(probe);
            return probe;
        },
        {
            onSuccess: (probe) =>
                toast.success(`Analysis "${probe.name}" added`),
            onError: () => toast.error("Something went wrong."),
        },
    );
    const updateAction = useAsyncState(
        async (id: UUID, probe: ProbeBase) => {
            await updateColumn(id, probe);
            return probe;
        },
        {
            onSuccess: (probe) =>
                toast.success(`Analysis "${probe.name}" updated`),
            onError: () => toast.error("Something went wrong."),
        },
    );

    return (
        <ColumnSheet
            addedProbeIDs={addedProbeIDs}
            onSelect={selectAction.submit}
            onCreate={createAction.submit}
            onUpdate={updateAction.submit}
            onStarChange={setColumnStar}
        />
    );
};

export const V3GridViewRoute = () => {
    const { project_id } = useParams<{ project_id: string }>();
    const openDocument = useAnalyze((s) => s.openDocument);
    if (project_id === undefined || !validateUUID(project_id)) {
        return <NotFoundRoute />;
    }

    return (
        <Suspense fallback={<GridViewContentSkeleton />}>
            <ProjectContainer id={project_id}>
                <ContextContextProvider messageID={project_id}>
                    <GridViewContextProvider
                        key={project_id}
                        messageID={project_id}
                    >
                        <ActionsContextProvider messageID={project_id}>
                            <DocumentLinkContext
                                value={{ onNavigate: openDocument }}
                            >
                                <FileUploadWrapper>
                                    <GridView disableRowSelection />
                                </FileUploadWrapper>
                                <Overlay />
                                <GridViewColumnSheet />
                            </DocumentLinkContext>
                        </ActionsContextProvider>
                    </GridViewContextProvider>
                </ContextContextProvider>
            </ProjectContainer>
        </Suspense>
    );
};

const FileUploadWrapper = (props: PropsWithChildren) => {
    const api = useApi();
    const maxDocumentCount = useFeatureFlagNumber(
        FeatureFlagNumber.REPORT_MAX_DOCUMENTS,
    );
    const { project, mutate } = useProject();
    const remainingCount = maxDocumentCount - project.documents.length;
    const onUploadComplete = async (files: UploadFile[]) => {
        await api.report_add_documents(
            project.id,
            files.filter(isFileUploaded).map((f) => ({
                type: ContextType.EXISTING_DOCUMENT,
                document_id: f.document_info.id,
            })),
        );
        await mutate();
    };
    return (
        <FileUploadContextProvider
            maxFiles={remainingCount}
            onUploadComplete={onUploadComplete}
        >
            {props.children}
        </FileUploadContextProvider>
    );
};
