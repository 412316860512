import { init as fsInit } from "@fullstory/browser";
import { useEffect } from "react";

import { useConfig } from "@/hooks/use-config";

let fsInitialized = false;
const initializeFullstory = (fullstoryEnabled: boolean, orgId: string) => {
    if (!fsInitialized && fullstoryEnabled) {
        fsInit({ orgId, devMode: false });
        fsInitialized = true;
    }
};

export const FullStoryConfig = () => {
    const { featureFlags, fullstoryOrgId } = useConfig();
    useEffect(() => {
        initializeFullstory(featureFlags.FULLSTORY_ENABLED, fullstoryOrgId);
    }, [featureFlags.FULLSTORY_ENABLED, fullstoryOrgId]);
    return null;
};
