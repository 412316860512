import { createContext } from "react";

import { ProbeBase } from "@/api/rest";
import { Probe } from "@/api/types";
import { UUID } from "@/types/base";
import { emptyFunction } from "@/utils/empty-function";

type Ret<T> = Promise<T> | T;

export type ColumnSheetContext = {
    addedProbeIDs: Set<UUID>;
    onSelect: (probe: Probe) => Ret<void>;
    onCreate: (probe: ProbeBase) => Ret<void>;
    onUpdate: (id: UUID, probe: ProbeBase) => Ret<void>;
    onStarChange: (probe_id: UUID, starred: boolean) => Ret<void>;
};

export const ColumnSheetContext = createContext<ColumnSheetContext>({
    addedProbeIDs: new Set(),
    onSelect: emptyFunction,
    onCreate: emptyFunction,
    onUpdate: emptyFunction,
    onStarChange: emptyFunction,
});
