import { BookOpenIcon, PlusIcon, XIcon } from "lucide-react";
import { Suspense } from "react";

import { ColumnSheetContext } from "@/components/document-table/columns/context";
import { CreateColumn } from "@/components/document-table/columns/create-column";
import { EditColumn } from "@/components/document-table/columns/edit-column";
import {
    ProbesLibrary,
    ProbesLibrarySkeleton,
} from "@/components/document-table/columns/probes-library";
import { SystemColumns } from "@/components/document-table/columns/system-columns";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import {
    SheetClose,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from "@/components/ui/sheet";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Page, PageType, useColumnSheet } from "@/hooks/use-column-sheet";

type PointerDownOutsideEvent = CustomEvent<{
    originalEvent: PointerEvent;
}>;

const getMinWidth = (type: PageType): string => {
    switch (type) {
        case PageType.edit:
        case PageType.create:
            return "800px";
        case PageType.presets:
        case PageType.starred:
            return "600px";
        default:
            return type satisfies never;
    }
};

const getSheetTitle = (page: Page): string => {
    switch (page.type) {
        case PageType.presets:
        case PageType.starred:
            return "Select Analysis";
        case PageType.create:
            return "Create Analysis";
        case PageType.edit:
            return "Edit Analysis";
    }
};

export const ColumnSheet = (props: ColumnSheetContext) => {
    const isOpen = useColumnSheet((s) => s.isOpen);
    const setOpen = useColumnSheet((s) => s.setOpen);
    const page = useColumnSheet((s) => s.page);

    const handlePointerDownOutside = (e: PointerDownOutsideEvent) => {
        if (page.type === PageType.create) {
            e.preventDefault();
        }
    };

    return (
        <ColumnSheetContext value={props}>
            <Sheet open={isOpen} onOpenChange={setOpen}>
                <SheetContent
                    side="right"
                    className="focus:ring-none flex flex-col border-none bg-transparent p-0 shadow-none transition-[min-width]"
                    style={{ minWidth: getMinWidth(page.type) }}
                    withCloseButton={false}
                    onPointerDownOutside={handlePointerDownOutside}
                >
                    <div className="bg-background m-2 flex grow flex-col space-y-4 overflow-hidden rounded-lg border shadow-lg">
                        <SheetHeader className="min-h-12 flex-row items-center justify-between space-y-0 border-b px-2">
                            <SheetTitle className="px-2">
                                {getSheetTitle(page)}
                            </SheetTitle>
                            <SheetDescription className="sr-only">
                                Add a Brightwave analysis or create your custom
                                analysis
                            </SheetDescription>
                            <SheetClose asChild>
                                <Button variant="ghost" size="icon-sm">
                                    <XIcon className="size-4" />
                                </Button>
                            </SheetClose>
                        </SheetHeader>
                        <Content page={page} />
                    </div>
                </SheetContent>
            </Sheet>
        </ColumnSheetContext>
    );
};

const AnalysisTabs = () => {
    const page = useColumnSheet((s) => s.page);
    const setPage = useColumnSheet((s) => s.setPage);
    const handleChange = (val: string) =>
        setPage(val as Exclude<PageType, PageType.edit>);
    return (
        <Tabs value={page.type} onValueChange={handleChange} className="px-4">
            <TabsList className="flex">
                <TabsTrigger value={PageType.presets} className="flex-1 gap-2">
                    Presets
                </TabsTrigger>
                <TabsTrigger value={PageType.starred} className="flex-1 gap-2">
                    <BookOpenIcon className="size-4" />
                    Your Library
                </TabsTrigger>
                <TabsTrigger value={PageType.create} className="flex-1 gap-2">
                    <PlusIcon className="size-4" />
                    New Analysis
                </TabsTrigger>
            </TabsList>
        </Tabs>
    );
};

const Content = ({ page }: { page: Page }) => {
    const close = useColumnSheet((s) => s.close);
    switch (page.type) {
        case PageType.create:
            return (
                <>
                    <AnalysisTabs />
                    <CreateColumn onSuccess={close} />
                </>
            );
        case PageType.presets:
            return (
                <>
                    <AnalysisTabs />
                    <Suspense fallback={<ProbesLibrarySkeleton />}>
                        <SystemColumns />
                    </Suspense>
                </>
            );
        case PageType.starred:
            return (
                <>
                    <AnalysisTabs />
                    <Suspense fallback={<ProbesLibrarySkeleton showTabs />}>
                        <ProbesLibrary />
                    </Suspense>
                </>
            );
        case PageType.edit:
            return <EditColumn probe={page.probe} onSuccess={close} />;
        default:
            return page satisfies never;
    }
};
