import stableHash from "stable-hash";

import { TableFinding } from "@/api/types";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";

type StructuredTableProps = {
    finding: TableFinding;
};

export const StructuredTable = ({ finding }: StructuredTableProps) => {
    const { column_headers, rows } = finding.structured_content;

    return (
        <Table className="border">
            <TableHeader>
                <TableRow>
                    {column_headers.map((header) => (
                        <TableHead key={header}>{header}</TableHead>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={stableHash(row)}>
                        {column_headers.map((header) => (
                            <TableCell key={`${stableHash(row)}-${header}`}>
                                {row[header]}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
