import { ColumnOrderState } from "@tanstack/react-table";
import useSWRImmutable from "swr/immutable";

import { Column, DocumentFindingGroup, UUID } from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { SynthesisContent } from "@/components/document-table/synthesis/synthesis-details";
import { RestoreScrollPosition } from "@/components/restore-scroll-position";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useApi } from "@/hooks/use-api";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { useGridView } from "@/hooks/use-grid-view-context";
import {
    getColumnId,
    getColumnIdFromFindingGroup,
    getColumnTitle,
    getColumnType,
} from "@/utils/columns";
import { nonNull } from "@/utils/fn";
import * as objMap from "@/utils/object-map";
import { simpleHash } from "@/utils/string-helpers";

const getSortFn = (order: ColumnOrderState) => {
    const lookup = order.reduce(
        (map, item, index) => map.set(item, index),
        new Map<string, number>(),
    );
    return (a: Column, b: Column): number => {
        const a_idx = lookup.get(getColumnId(a)) ?? -1;
        const b_idx = lookup.get(getColumnId(b)) ?? -1;
        return a_idx - b_idx;
    };
};

const Report = (props: { ids: objMap.ObjMap<UUID[]> }) => {
    const api = useApi();
    const reportID = useGridView((s) => s.report.id);
    const { data: findingGroups } = useSWRImmutable(
        [props.ids, "report_v0"],
        async ([ids]) => await api.fetch_synthesis_list(reportID, ids),
        { suspense: true },
    );
    const columnOrder = useGridView((s) => s.columnOrder);
    const columns = useGridView((s) => s.columns);
    const orderedColumns = columns.sort(getSortFn(columnOrder));

    return orderedColumns
        .map(
            (col) =>
                [col, objMap.get(findingGroups, getColumnId(col))] as const,
        )
        .filter((tpl): tpl is [Column, DocumentFindingGroup] => nonNull(tpl[1]))
        .map(([col, fg]) => (
            <div key={fg.id}>
                <div className="m-2 flex items-center gap-4 rounded-lg bg-purple-300 p-2.5 px-4 dark:bg-purple-900">
                    <ProbeTypeIcon
                        type={getColumnType(col)}
                        className="size-6"
                    />
                    <p className="font-headline text-xl font-bold">
                        {getColumnTitle(col)}
                    </p>
                </div>
                <div className="bg-background">
                    <SynthesisContent findingGroup={fg} />
                </div>
            </div>
        ));
};

export const ReportContent = () => {
    const enabled = useFeatureFlagBool(FeatureFlagBool.REPORT_V0_ENABLED);
    const finding_goups = useGridView((s) => s.finding_groups);
    if (!enabled) return null;

    const ids = Array.from(finding_goups.values()).reduce(
        (obj: objMap.ObjMap<UUID[]>, fg) => {
            const key = getColumnIdFromFindingGroup(fg);
            return key != undefined
                ? objMap.update(obj, key, (v = []) => [...v, fg.id])
                : obj;
        },
        {},
    );
    const hash = simpleHash(JSON.stringify(ids));

    return (
        <RestoreScrollPosition storageKey={hash}>
            <Report ids={ids} />
        </RestoreScrollPosition>
    );
};
