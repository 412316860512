import { ThemeProvider } from "next-themes";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { ErrorBoundary } from "@/components/error-boundary";
import { Favicon } from "@/components/favicon";
import { FullStoryConfig } from "@/components/FullStoryConfig";
import { PageLoader } from "@/components/page-loader";
import { SentryConfig } from "@/components/SentryConfig";
import { Toaster } from "@/components/ui/sonner";
import { AppContextProvider } from "@/context/app-context-provider";
import { ConfigContextProvider } from "@/context/config-context-provider";
import { router } from "@/router";
import { ErrorPage } from "@/routes/error";

import "@/styles/global.css";

/**
 * Render App
 */
createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <ThemeProvider storageKey="color-scheme" attribute="class" enableSystem>
            <ErrorBoundary
                fallback={({ error }) => (
                    <ErrorPage
                        error={error instanceof Error ? error : undefined}
                    />
                )}
            >
                <Suspense fallback={<PageLoader />}>
                    <ConfigContextProvider>
                        <AppContextProvider>
                            <RouterProvider router={router} />
                        </AppContextProvider>
                        <Toaster richColors />
                        <Favicon />
                        <SentryConfig />
                        <FullStoryConfig />
                    </ConfigContextProvider>
                </Suspense>
            </ErrorBoundary>
        </ThemeProvider>
    </StrictMode>,
);
