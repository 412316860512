import {
    Probe,
    GenericFindingContent,
    FindingContentType,
    AsyncFindingGroup,
    DocumentFindingGroup,
    FindingGroupInfo,
    FindingGroupStatus,
} from "@/api/types";
import {
    AsyncState,
    createSuccess,
    mergeAsyncValue,
} from "@/utils/async-value";
import { uniqueBy } from "@/utils/collection";
import { nonNull } from "@/utils/fn";

export const getProbeTitle = (probe?: Probe | null): string => {
    if (!probe) return "Custom Column";
    return probe.name;
};

export const getFindingContentForAction = (
    finding: GenericFindingContent,
): string | undefined => {
    switch (finding.content_type) {
        case FindingContentType.boolean:
            return `${finding.value ? "YES" : "NO"}\n${finding.text}`;
        case FindingContentType.full_text:
        case FindingContentType.long_text:
        case FindingContentType.short_text:
        case FindingContentType.list_item:
            return finding.text;
        case FindingContentType.titled_long_text:
        case FindingContentType.dated_long_text:
            return `${finding.title}\n\n${finding.text}`;
        case FindingContentType.titled_list:
            return [finding.title, ...finding.key_points].join("\n\n");
        case FindingContentType.numerical:
            return finding.text;
        case FindingContentType.numerical_full_text:
        case FindingContentType.unstructured:
            return undefined;
        case FindingContentType.table:
            return `${finding.title}\n\n${finding.short_text}\n\n${finding.long_text}`;
        default:
            return finding satisfies never;
    }
};

export const getAllFindingContent = (
    finding: GenericFindingContent,
): string | undefined | null => {
    switch (finding.content_type) {
        case FindingContentType.boolean:
        case FindingContentType.list_item:
        case FindingContentType.short_text:
        case FindingContentType.long_text:
            return [finding.text, finding.tag].filter(nonNull).join("\n\n");
        case FindingContentType.full_text:
            return [finding.headline, finding.title, finding.text, finding.tag]
                .filter(nonNull)
                .join("\n\n");
        case FindingContentType.numerical_full_text:
            return [
                finding.value + " " + finding.unit,
                finding.headline,
                finding.title,
                finding.text,
                ,
                finding.tag,
            ]
                .filter(nonNull)
                .join("\n\n");
        case FindingContentType.titled_long_text:
        case FindingContentType.dated_long_text:
            return [finding.title, finding.text, finding.tag]
                .filter(nonNull)
                .join("\n\n");
        case FindingContentType.titled_list:
            return [finding.title, ...finding.key_points, finding.tag]
                .filter(nonNull)
                .join("\n\n");
        case FindingContentType.numerical:
            return [finding.value, finding.unit, finding.text, finding.tag]
                .filter(nonNull)
                .join("\n\n");
        case FindingContentType.table:
            return `${finding.title}\n\n${finding.short_text}\n\n${finding.long_text}`;
        case FindingContentType.unstructured:
            return undefined;
        default:
            return finding satisfies never;
    }
};

const getAsyncFindingGroupFactory =
    (state: Extract<AsyncState, AsyncState.initial | AsyncState.queued>) =>
    (
        finding_group: FindingGroupInfo | DocumentFindingGroup,
    ): AsyncFindingGroup => {
        if (finding_group.status === FindingGroupStatus.processing) {
            return {
                ...finding_group,
                findings: { state: AsyncState.queued },
            };
        }
        return {
            ...finding_group,
            findings:
                "findings" in finding_group && nonNull(finding_group.findings)
                    ? createSuccess(finding_group.findings)
                    : { state },
        };
    };

export const createInitialAsyncFindingGroup = getAsyncFindingGroupFactory(
    AsyncState.initial,
);

export const createQueuedAsyncFindingGroup = getAsyncFindingGroupFactory(
    AsyncState.queued,
);

export const createAsyncFindingGroup = <P = Probe>(
    finding_group: DocumentFindingGroup<P>,
): AsyncFindingGroup<P> => {
    if (finding_group.status === FindingGroupStatus.processing) {
        return {
            ...finding_group,
            findings: { state: AsyncState.queued },
        };
    }
    return {
        ...finding_group,
        findings: createSuccess(finding_group.findings),
    };
};

export const getFindingGroup = (
    async_finding_group: AsyncFindingGroup,
): DocumentFindingGroup => ({
    ...async_finding_group,
    findings:
        async_finding_group.findings.state === AsyncState.success
            ? async_finding_group.findings.value
            : [],
});

export const maybeGetFindingGroup = (
    async_finding_group: AsyncFindingGroup | null | undefined,
): DocumentFindingGroup | null | undefined =>
    nonNull(async_finding_group)
        ? getFindingGroup(async_finding_group)
        : async_finding_group;

export const mergeAsyncFindingGroup = (
    a: AsyncFindingGroup,
    b: AsyncFindingGroup,
): AsyncFindingGroup => ({
    ...a,
    ...b,
    findings: mergeAsyncValue(a.findings, b.findings, (a, b) =>
        uniqueBy([...a, ...(b ?? [])], (f) => f.id),
    ),
});
