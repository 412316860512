import { HTMLAttributes } from "react";

import { DocumentInfo } from "@/api/types";
import { ExternalRef } from "@/components/action-message/external-ref";
import { DebugID } from "@/components/debug/debug-id";
import { Headline } from "@/components/headline";
import { formatDate } from "@/utils/time";

interface Props extends HTMLAttributes<HTMLDivElement> {
    info: DocumentInfo;
}

export const DocumentHeader = ({ info, ...props }: Props) => (
    <div {...props}>
        <DebugID label="Document" id={info.id} />
        <Headline level={2}>{info.title}</Headline>
        <div className="divide-border flex items-center divide-x text-sm **:px-2">
            {info.content_date ? (
                <>
                    <span>{formatDate(info.content_date)}</span>
                    <ExternalRef info={info} className="font-medium" />
                </>
            ) : (
                <ExternalRef info={info} className="font-medium" />
            )}
        </div>
    </div>
);
