import { useConfig } from "@/hooks/use-config";
import { useFavicon } from "@/hooks/use-favicon";

const getFavicon = (isDev: boolean, env: string): string => {
    if (isDev) return "/logo_dev.svg";
    if (env === "staging") return "/logo_staging.svg";
    return "/logo_adaptive.svg";
};

export const Favicon = () => {
    const { isDev, env } = useConfig();
    useFavicon(getFavicon(isDev, env));
    return null;
};
