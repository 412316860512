import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CreateSynthesisProbeSchema } from "@/api/rest";
import { UUID } from "@/api/types";
import { CreateSection } from "@/components/report-builder/create-section";
import { Input } from "@/components/ui/input";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { notEmptyOrNull } from "@/utils/string-helpers";

export const V3ReportBuilder = () => {
    const { project_id } = useParams<{ project_id: UUID }>();
    const api = useApi();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");

    const createAction = useAsyncState(
        async (data: CreateSynthesisProbeSchema) => {
            const report = await api.v3_create_report(project_id!, {
                title: notEmptyOrNull(title) ? title : undefined,
                synthesis_probes: [data],
            });
            navigate(
                `/v3/project/${project_id}/report/${report.info.thread_id}`,
            );
        },
    );

    return (
        <div className="container grow space-y-10 overflow-scroll py-10">
            <Input
                placeholder="Report title..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <CreateSection onCreate={createAction.submit} />
        </div>
    );
};
