import { Column, FindingGroupInfo, ProbeType, UUID } from "@/api/types";

export enum ColumnFilterType {
    none = 0,
    text,
    boolean,
    numerical,
}

export const getColumnId = (c: Column): string => c.details.id;

export const getColumnTitle = (column: Column): string => column.details.name;

export const getColumnType = (column: Column): ProbeType => column.details.type;

export const getColumnIdFromFindingGroup = (fg: FindingGroupInfo): UUID =>
    fg.probe.id;

export const getProbeID = (c: Column): UUID => c.details.id;

export const getColumnFilterType = (c: Column): ColumnFilterType => {
    switch (c.details.type) {
        case ProbeType.boolean:
        case ProbeType.strict_boolean:
            return ColumnFilterType.boolean;
        case ProbeType.finding_list:
        case ProbeType.list:
        case ProbeType.markdown:
        case ProbeType.short_text:
        case ProbeType.text:
        case ProbeType.table:
            return ColumnFilterType.text;
        case ProbeType.number:
            return ColumnFilterType.numerical;
        default:
            return c.details.type satisfies never;
    }
};
