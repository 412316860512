import { FilePlus2 } from "lucide-react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { ContextItem, ContextType, FileStatus } from "@/api/types";
import { DocumentSearch } from "@/components/analyze/document-search";
import { Dropzone } from "@/components/analyze/dropzone";
import { UploadToast } from "@/components/analyze/upload-toast";
import { ButtonWithTooltip } from "@/components/button-with-tooltip";
import { Headline } from "@/components/headline";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { FeatureFlagNumber } from "@/conf/feature-flags";
import { FileUploadContextProvider } from "@/context/file-upload-context-provider";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useFeatureFlagNumber } from "@/hooks/use-feature-flag";
import { useFileUploadContext } from "@/hooks/use-file-upload-context";
import { usePageTitle } from "@/hooks/use-page-title";
import { AppLayout } from "@/layouts/app-layout";
import { encodeContextItem } from "@/utils/context-items";
import { isFileUploaded } from "@/utils/file-upload";

const Impl = () => {
    const maxDocumentCount = useFeatureFlagNumber(
        FeatureFlagNumber.REPORT_MAX_DOCUMENTS,
    );
    const api = useApi();
    const navigate = useNavigate();
    const { open } = useFileUploadContext((s) => s.dropzone);
    const files = useFileUploadContext((s) => s.files);
    const resetFiles = useFileUploadContext((s) => s.reset);

    const createAction = useAsyncState(
        async (items: ContextItem[]) =>
            await api.create_report(undefined, items.map(encodeContextItem)),
        {
            onSuccess: (report) => navigate(`/analyze/${report.id}`),
            onError: () =>
                toast.error("Something went wrong, please try again"),
        },
    );

    useEffect(() => {
        const uploads = Array.from(files.values());
        if (
            uploads.length > 0 &&
            uploads.every((f) => f.status >= FileStatus.uploaded) &&
            uploads.some(
                (f) =>
                    f.status >= FileStatus.uploaded &&
                    f.status <= FileStatus.ready,
            )
        ) {
            createAction.submit(
                uploads.filter(isFileUploaded).map((f) => ({
                    type: ContextType.EXISTING_DOCUMENT,
                    document_id: f.document_info.id,
                })),
            );
        }
    }, [files]);

    return (
        <AppLayout
            className="bg-page-background relative flex h-screen grow flex-col overflow-scroll"
            header={
                <Breadcrumb className="text-sm">
                    <BreadcrumbList className="flex-nowrap">
                        <BreadcrumbItem className="shrink-0">
                            <BreadcrumbLink
                                className="hover:text-background dark:hover:text-foreground underline-offset-4 hover:underline"
                                asChild
                            >
                                <NavLink to="/">Home</NavLink>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem className="text-background flex items-center font-semibold dark:text-neutral-400">
                            <span className="line-clamp-1">
                                Analyze Documents
                            </span>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            }
        >
            <div className="bg-background grow py-20">
                <div className="container space-y-10 text-center">
                    <Headline level={2} highlighted>
                        Start by Selecting Documents
                    </Headline>
                    <div className="mx-auto flex max-w-(--breakpoint-lg) grow flex-col items-center gap-6 text-left">
                        <DocumentSearch
                            confirmSelection
                            addItems={createAction.submit}
                            disabled={files.size > 0}
                            maxDocumentCount={maxDocumentCount}
                            placeholder="Find SEC Filings and Earnings Calls"
                            className="bg-background w-full"
                        />
                        <p>or</p>
                        <ButtonWithTooltip
                            variant="outline"
                            className="text-muted-foreground gap-2"
                            tooltip="Click to add documents or use Drag & Drop"
                            onClick={open}
                        >
                            <FilePlus2 className="size-4" />
                            Add Documents
                        </ButtonWithTooltip>
                    </div>
                </div>
            </div>
            <Dropzone />
            <UploadToast
                files={Array.from(files.values())}
                onDismiss={resetFiles}
            />
        </AppLayout>
    );
};

export const EmptyGridView = () => {
    usePageTitle("Analyze Documents");
    const maxDocumentCount = useFeatureFlagNumber(
        FeatureFlagNumber.REPORT_MAX_DOCUMENTS,
    );
    return (
        <FileUploadContextProvider maxFiles={maxDocumentCount}>
            <Impl />
        </FileUploadContextProvider>
    );
};
