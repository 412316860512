import { ProbeBase } from "@/api/rest";
import { Probe } from "@/api/types";
import { ColumnForm } from "@/components/document-table/column-form";
import { useColumnSheetContext } from "@/components/document-table/columns/use-column-sheet";
import { useAsyncState } from "@/hooks/use-async-state";

type Props = {
    probe: Probe;
    onSuccess?: () => void;
};

export const EditColumn = (props: Props) => {
    const updateColumn = useColumnSheetContext((s) => s.onUpdate);
    const updateAction = useAsyncState(
        async (data: ProbeBase) => await updateColumn(props.probe.id, data),
        { onSuccess: props.onSuccess },
    );
    return <ColumnForm probe={props.probe} action={updateAction} isUpdate />;
};
