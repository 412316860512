import { DocumentInfo, DocumentType, QuartrDocumentType } from "@/api/types";

export const getDocumentTitle = (info: DocumentInfo): string => {
    switch (info.doc_type) {
        case DocumentType.website:
        case DocumentType.pdf:
        case DocumentType.text:
        case DocumentType.spreadsheet:
        case DocumentType.quartr_transcript:
            return info.title;
        case DocumentType.sec_filing:
            return info.form_type;
        case DocumentType.earnings_transcript:
            return `${info.year} Q${info.quarter} Earnings Call`;
        default:
            return info satisfies never;
    }
};

export const getQuartrDocumentSuffix = (
    type: QuartrDocumentType,
): string | undefined => {
    switch (type) {
        case QuartrDocumentType.slides:
            return "Slides";
        case QuartrDocumentType.pdf_transcript:
        case QuartrDocumentType.transcript:
            return "Transcript";
        case QuartrDocumentType.quarterly_report:
        case QuartrDocumentType.report:
            return "Quarterly Report";
        case QuartrDocumentType.earnings_release_8k:
        case QuartrDocumentType.annual_report_10k:
        case QuartrDocumentType.annual_report_other:
        case QuartrDocumentType.annual_report_20f:
        case QuartrDocumentType.earnings_release_6k:
        case QuartrDocumentType.interim_report:
        case QuartrDocumentType.annual_report_40f:
        case QuartrDocumentType.press_release:
        case QuartrDocumentType.earnings_release_other:
            return undefined;
        default:
            return type satisfies never;
    }
};
