import {
    Braces,
    ChevronDown,
    Edit,
    RefreshCcw,
    StarIcon,
    Trash,
} from "lucide-react";
import { MouseEvent, PropsWithChildren, SyntheticEvent } from "react";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import { UpdateProbePayload } from "@/api/rest";
import { Column, Probe, UUID } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { Debugger } from "@/components/debug/debugger";
import { ColumnFocus } from "@/components/document-table/column-focus";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { SynthesisTrigger } from "@/components/document-table/synthesis/synthesis-trigger";
import { Markdown } from "@/components/markdown";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useApi } from "@/hooks/use-api";
import { UseAsyncState, useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { useColumnSheet } from "@/hooks/use-column-sheet";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { useGridView } from "@/hooks/use-grid-view-context";
import { cn } from "@/lib/utils";
import { AsyncState } from "@/utils/async-value";
import { getColumnId } from "@/utils/columns";
import { getProbeTitle, maybeGetFindingGroup } from "@/utils/finding-group";
import { bind, nonNull } from "@/utils/fn";

const RefreshColumn = ({ probe }: { probe: Probe }) => {
    const invalidateColumn = useGridView((s) => s.invalidateColumn);
    const action = useAsyncState(async (e: SyntheticEvent) => {
        e.stopPropagation();
        return await invalidateColumn(probe.id);
    });
    return (
        <AsyncButton
            variant="ghost"
            size="sm"
            type="button"
            action={action}
            className="w-full justify-start"
        >
            <RefreshCcw className="mr-2 size-4" />
            Refresh Findings
        </AsyncButton>
    );
};

const CopyColumnDebug = ({ probe }: { probe: Probe }) => {
    const api = useApi();
    const documentsToFindingGroups = useGridView(
        useShallow((s) => s.documents_to_finding_groups),
    );
    const documents = useGridView(useShallow((s) => s.documents));
    const findingGroups = useGridView(useShallow((s) => s.finding_groups));
    const all = Array.from(documentsToFindingGroups.entries()).map(
        ([doc_id, fg_ids]) => ({
            document_info: documents.get(doc_id)!,
            finding_group: fg_ids
                .map((id) => findingGroups.get(id))
                .filter(nonNull)
                .find((fg) => fg.probe.id === probe.id),
        }),
    );

    const action = useAsyncState(
        async () => {
            const allFetched = await Promise.all(
                all.map(async ({ document_info, finding_group }) => {
                    if (
                        finding_group == null ||
                        finding_group.findings.state === AsyncState.success
                    ) {
                        return {
                            document_info,
                            finding_group: maybeGetFindingGroup(finding_group),
                        };
                    }
                    const result = await api.fetch_analysis_by_id(
                        finding_group.id,
                    );
                    return {
                        document_info: document_info,
                        finding_group: result,
                    };
                }),
            );
            const data = JSON.stringify(allFetched, null, 2);
            await navigator.clipboard.writeText(data);
        },
        {
            onSuccess: () => toast.success("JSON copied to clipboard"),
            onError: () =>
                toast.error("Failed to load & copy all finding groups"),
        },
    );

    return (
        <AsyncButton
            variant="ghost"
            size="sm"
            className="w-full justify-start"
            action={action}
        >
            <Braces className="mr-2 size-4" />
            Copy Analysis Debug
        </AsyncButton>
    );
};

export const ColumnHeader = ({ column }: { column: Column }) => {
    const enableSynthesis = useFeatureFlagBool(
        FeatureFlagBool.SYNTHESIS_ENABLED,
    );
    const updateColumn = useGridView((s) => s.updateColumn);
    const deleteColumn = useGridView((s) => s.deleteColumn);
    const setProbeStar = useGridView((s) => s.setProbeStar);

    const deleteAction = useAsyncState(
        async () => await deleteColumn(column.details.id),
    );

    return (
        <>
            <ColumnFocus columnKey={getColumnId(column)} />
            <ProbeHeader
                probe={column.details}
                updateColumn={updateColumn}
                deleteAction={deleteAction}
                onStarChanged={(starred: boolean) =>
                    setProbeStar(column.details.id, starred)
                }
            >
                <Debugger>
                    <CopyColumnDebug probe={column.details} />
                    <RefreshColumn probe={column.details} />
                    <Separator />
                </Debugger>
                {enableSynthesis && (
                    <>
                        <SynthesisTrigger probe={column.details} />
                        <Separator />
                    </>
                )}
            </ProbeHeader>
        </>
    );
};

type ProbeHeaderProps = {
    probe: Probe;
    deleteAction: UseAsyncState<[], unknown>;
    updateColumn: (
        probe_id: UUID,
        payload: UpdateProbePayload,
    ) => Promise<void>;
    onStarChanged?: (starred: boolean) => void;
};

const ProbeHeader = ({
    probe,
    deleteAction,
    onStarChanged,
    children,
}: PropsWithChildren<ProbeHeaderProps>) => {
    const api = useApi();
    const isSystemProbe = probe.owner_id === null;
    const [isOpen, openActions] = useBoolean(false);
    const editProbe = useColumnSheet((s) => s.editProbe);
    const handleClick = (e: MouseEvent) => {
        if (e.target instanceof HTMLElement && e.target.closest("button")) {
            openActions.close();
        }
    };
    const starAction = useAsyncState(
        async () => {
            if (probe.starred) {
                await api.unstar_probe(probe.id);
            } else {
                await api.star_probe(probe.id);
            }
            return !probe.starred;
        },
        {
            onSuccess: (starred) => {
                onStarChanged?.(starred);
                toast.success(
                    starred
                        ? "Analysis added to Favorites"
                        : "Analysis removed from Favorites",
                );
            },
        },
    );
    return (
        <Popover open={isOpen} onOpenChange={openActions.set}>
            <PopoverTrigger asChild>
                <Button variant="ghost" size="sm" className="gap-2">
                    <ProbeTypeIcon type={probe.type} />
                    <span>{getProbeTitle(probe)}</span>
                    <ChevronDown className="size-4" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-96 p-0" onClick={handleClick}>
                {!isSystemProbe && (
                    <div className="space-y-2 border-b p-4">
                        <p className="text-sm font-bold">{probe.name}</p>
                        <Markdown className="text-sm">{probe.prompt}</Markdown>
                    </div>
                )}
                <div className="flex flex-col gap-1 p-1">
                    {children}
                    {!isSystemProbe && (
                        <>
                            <AsyncButton
                                variant="ghost"
                                size="sm"
                                action={starAction}
                                className="justify-start hover:bg-yellow-100 hover:text-yellow-700"
                            >
                                <StarIcon
                                    className={cn(
                                        "mr-2 size-4",
                                        probe.starred && "fill-current",
                                    )}
                                />
                                {probe.starred
                                    ? "Remove Analysis from Favorites"
                                    : "Add Analysis to Favorites"}
                            </AsyncButton>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={bind(editProbe, probe)}
                                className="justify-start gap-2"
                            >
                                <Edit className="size-4" />
                                Edit
                            </Button>
                        </>
                    )}
                    <AsyncButton
                        variant="ghost-destructive"
                        size="sm"
                        action={deleteAction}
                        className="justify-start"
                    >
                        <Trash className="mr-2 size-4" />
                        Remove
                    </AsyncButton>
                </div>
            </PopoverContent>
        </Popover>
    );
};
