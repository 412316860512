import { Loader2, Zap } from "lucide-react";
import { HTMLAttributes, ReactNode, Suspense, use } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";

import { CustomerStatus } from "@/api/types";
import { BrightwaveLogo } from "@/components/brightwave-logo";
import { ErrorBoundary } from "@/components/error-boundary";
import { OfflineIndicator } from "@/components/offline-indicator";
import { TrialBanner } from "@/components/paywall/trial-banner";
import { UserMenu } from "@/components/user-menu";
import { SubscriptionContainer } from "@/container/paywall/subscription";
import { AppContext } from "@/context/app-context";
import { useAppOutdated } from "@/hooks/use-app-outdated";
import { useV3 } from "@/hooks/use-v3";
import { cn } from "@/lib/utils";
import { canSeeBillingSettings } from "@/utils/user";

export const AppLayout = ({
    header,
    className,
    children,
    ...props
}: HTMLAttributes<HTMLElement> & { header?: ReactNode }) => {
    const [v3] = useV3();
    const location = useLocation();
    const { user, logout } = use(AppContext);
    const isOutdated = useAppOutdated();
    const showTrialBanner =
        user &&
        user.status === CustomerStatus.trialing &&
        canSeeBillingSettings(user);
    if (v3 && !location.pathname.startsWith("/v3")) {
        return <Navigate to={`/v3${location.pathname}`} />;
    }
    return (
        <>
            <OfflineIndicator />
            {showTrialBanner && (
                <ErrorBoundary>
                    <SubscriptionContainer fetchKey="trial_banner">
                        {TrialBanner}
                    </SubscriptionContainer>
                </ErrorBoundary>
            )}
            {isOutdated && (
                <div className="fixed top-0 right-0 left-0 z-50 flex h-10 items-center gap-3 bg-yellow-100 px-4 text-yellow-700">
                    <Zap className="size-4 fill-current" />
                    <p>
                        Brightwave just got better. To get the latest updates
                        refresh the page or{" "}
                        <a
                            href="#"
                            onClick={() => window.location.reload()}
                            className="underline underline-offset-4"
                        >
                            click here
                        </a>
                        .
                    </p>
                </div>
            )}
            <header
                className={cn(
                    "bg-foreground text-background dark:text-foreground fixed right-0 left-0 z-10 flex h-14 w-full items-center justify-between px-4 dark:bg-neutral-900 print:hidden",
                    isOutdated || showTrialBanner ? "top-10 border-t" : "top-0",
                )}
            >
                <div className="flex-1">
                    <Link
                        to="/dashboard"
                        className="transition-opacity hover:opacity-85 active:opacity-100"
                    >
                        <BrightwaveLogo />
                    </Link>
                </div>
                {header}
                <div className="flex flex-1 justify-end">
                    {user && <UserMenu user={user} onLogout={logout} />}
                </div>
            </header>
            <main
                className={cn(
                    "print:pt-0",
                    isOutdated || showTrialBanner ? "pt-24" : "pt-14",
                    className,
                )}
                {...props}
            >
                <Suspense
                    fallback={
                        <div className="flex justify-center p-20">
                            <Loader2 className="animate-spin" />
                        </div>
                    }
                >
                    {children ?? <Outlet />}
                </Suspense>
            </main>
        </>
    );
};
