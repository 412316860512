import { PropsWithChildren } from "react";

import { useConfig } from "@/hooks/use-config";
import { useDevMode } from "@/hooks/use-dev-mode";

type Props = {
    showOnDevEnv?: boolean;
};

export const Debugger = ({
    showOnDevEnv = false,
    children,
}: PropsWithChildren<Props>) => {
    const [devMode] = useDevMode();
    const { isDev } = useConfig();
    if (devMode || (showOnDevEnv && isDev)) {
        return children;
    }
    return null;
};
