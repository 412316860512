import { Trash } from "lucide-react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import useSWRInfinite from "swr/infinite";

import { DocumentCollection, UUID } from "@/api/types";
import { ButtonWithConfirmation } from "@/components/admin/button-with-confirmation";
import { DebugID } from "@/components/debug/debug-id";
import { ErrorAlert } from "@/components/error-alert";
import { Headline } from "@/components/headline";
import { InfiniteScroll } from "@/components/infinite-scroll";
import { Button } from "@/components/ui/button";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { last } from "@/utils/collection";
import { bind } from "@/utils/fn";
import { getCursorKeyFn } from "@/utils/pagination";
import { getReportTitle } from "@/utils/report";
import { pluralize } from "@/utils/string-helpers";

const PAGE_SIZE = 25;

export const V3Dashboard = () => {
    const api = useApi();
    const { data, error, isLoading, isValidating, setSize, mutate } =
        useSWRInfinite(
            getCursorKeyFn(PAGE_SIZE, "history"),
            async ({ pagination }) => await api.fetch_report_list(pagination),
            { revalidateOnMount: true },
        );

    const onDelete = (report_id: UUID) => {
        mutate((data) => {
            if (!data) return data;
            return data.map((page) => ({
                ...page,
                items: page.items.filter((report) => report.id !== report_id),
            }));
        });
    };

    const hasNextPage =
        data != null && last(data)?.page_info.has_next_page === true;

    return (
        <div className="container grow space-y-4 overflow-scroll py-10">
            <Headline level={3} highlighted>
                Projects
            </Headline>
            <Button asChild>
                <Link to="/v3/project/new">New Project</Link>
            </Button>
            <InfiniteScroll
                className="space-y-4"
                lookahead="10%"
                isLoading={isValidating && !isLoading}
                hasNextPage={hasNextPage}
                onNextPage={() => setSize((size) => size + 1)}
            >
                {data
                    ?.flatMap((p) => p.items)
                    .map((report) => (
                        <Project
                            key={report.id}
                            report={report}
                            onDelete={bind(onDelete, report.id)}
                        />
                    ))}
                {error && <ErrorAlert error={error} message="Failed to load" />}
            </InfiniteScroll>
        </div>
    );
};

const Project = ({
    report,
    onDelete,
}: {
    report: DocumentCollection;
    onDelete?: () => void;
}) => {
    const api = useApi();
    const deleteAction = useAsyncState(
        async () => await api.delete_report(report.id),
        {
            onSuccess: (result) => {
                if (result) {
                    toast.success("Report deleted");
                    onDelete?.();
                } else {
                    toast.error("Report deletion failed");
                }
            },
        },
    );
    return (
        <div
            className="group bg-background hover:bg-muted relative cursor-pointer rounded-md border p-4 transition-colors"
            role="button"
        >
            <div className="space-y-2.5">
                <DebugID label="ID" id={report.id} />
                <Headline level={3}>{getReportTitle(report)}</Headline>
                <div className="grid grid-cols-5 gap-4">
                    <div className="rounded border border-orange-300 bg-orange-100 p-2 text-sm text-orange-500 dark:border-orange-700 dark:bg-orange-950">
                        <p className="font-headline text-2xl">
                            {report.documents.length}
                        </p>
                        <p>{pluralize(report.documents.length, "Document")}</p>
                    </div>
                </div>
            </div>
            <Link
                to={`/v3/project/${report.id}`}
                className="absolute inset-0"
            />
            <ButtonWithConfirmation
                message="Do you want to delete this project. This action can not be undone."
                confirmButtonVariant="destructive"
                variant="ghost-destructive"
                size="icon-sm"
                className="hover:border-destructive/30 absolute top-2 right-2 opacity-0 transition-all group-hover:opacity-100 hover:border"
                action={deleteAction}
            >
                <Trash className="size-4" />
            </ButtonWithConfirmation>
        </div>
    );
};
