import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { PropsWithChildren, ReactNode, use } from "react";
import useSWRImmutable from "swr/immutable";

import { StripeContext } from "@/context/stripe-context";
import { useApi } from "@/hooks/use-api";
import { useConfig } from "@/hooks/use-config";

const stripePromiseCache = new Map<string, Promise<Stripe | null>>();
const getStripePromise = (key: string): Promise<Stripe | null> => {
    if (!stripePromiseCache.has(key)) {
        stripePromiseCache.set(key, loadStripe(key));
    }
    return stripePromiseCache.get(key)!;
};

const StripeClientSecretContainer = (props: {
    children: (data: { clientSecret: string }) => ReactNode;
}) => {
    const api = useApi();
    const { data: clientSecret } = useSWRImmutable(
        "client_secret",
        async () => await api.fetch_stripe_client_secret(),
        { suspense: true },
    );
    return props.children({ clientSecret });
};

export const StripeElementsForPayments = ({
    clientSecret,
    ...props
}: PropsWithChildren<{ clientSecret: string }>) => {
    const { stripePublicKey } = useConfig();
    return (
        <Elements
            stripe={getStripePromise(stripePublicKey)}
            options={{ clientSecret }}
            {...props}
        />
    );
};

export const StripeElements = ({
    paymentIntent,
    ...props
}: PropsWithChildren<{ paymentIntent?: boolean }>) => {
    const { clientSecret } = use(StripeContext);
    const { stripePublicKey } = useConfig();
    if (paymentIntent) {
        if (clientSecret) {
            return (
                <StripeElementsForPayments
                    clientSecret={clientSecret}
                    {...props}
                />
            );
        }
        return (
            <StripeClientSecretContainer>
                {({ clientSecret }) => (
                    <StripeElementsForPayments
                        clientSecret={clientSecret}
                        {...props}
                    />
                )}
            </StripeClientSecretContainer>
        );
    }
    return <Elements stripe={getStripePromise(stripePublicKey)} {...props} />;
};
