import { HTMLAttributes, useRef } from "react";

import { ActionMessage } from "@/components/action-message/action-message";
import { CollapsedActionMessage } from "@/components/action-message/collapsed";
import { CollapsedActionSkeleton } from "@/components/action-message/skeletons";
import { AutoScrollContainer } from "@/components/action-panel/auto-scroll-container";
import { DiscoverNullstate } from "@/components/action-panel/nullstate";
import { ErrorAlert } from "@/components/error-alert";
import { InfiniteScroll } from "@/components/infinite-scroll";
import { Button } from "@/components/ui/button";
import { useActionsContext } from "@/hooks/use-actions-context";
import { clamp } from "@/utils/math";

interface Props extends HTMLAttributes<HTMLDivElement> {
    disableInquiry?: boolean;
}

export const ActionHistory = ({ disableInquiry = false, ...props }: Props) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const actions = useActionsContext((s) => s.actions);
    const collapsedActions = useActionsContext((s) => s.collapsedActions);
    const isLoading = useActionsContext((s) => s.isLoading);
    const nasNextPage = useActionsContext(
        (s) => s.pageInfo?.has_next_page === true,
    );
    const fetchMore = useActionsContext((s) => s.fetchMoreActions);
    const error = useActionsContext((s) => s.error);
    const itemsToLoad = useActionsContext((s) =>
        s.pageInfo === undefined
            ? s.pageSize
            : clamp(s.historyCount - s.historyFetched, 0, s.pageSize),
    );
    const filter = useActionsContext((s) => s.actionsFilter);
    const clearFilter = useActionsContext((s) => s.clearFilter);
    const hasActions = actions.size > 0;
    const filteredActions =
        filter.size > 0
            ? Array.from(actions.values()).filter((action) =>
                  filter.has(action.type),
              )
            : Array.from(actions.values());
    const showNullState = !isLoading && !hasActions;

    return (
        <AutoScrollContainer
            ref={scrollContainerRef}
            disableAutoScroll={showNullState}
            {...props}
        >
            <InfiniteScroll
                className="@container/chat space-y-2 px-2 pt-2"
                rootRef={scrollContainerRef}
                lookahead="10%"
                isLoading={isLoading}
                hasNextPage={nasNextPage}
                onNextPage={fetchMore}
                loader={Array.from(Array(itemsToLoad)).map((_, i) => (
                    <CollapsedActionSkeleton key={i} />
                ))}
                inverted
            >
                {error && (
                    <ErrorAlert
                        error={error}
                        message="Failed to load history..."
                    />
                )}
                {showNullState ? (
                    <DiscoverNullstate disableInquiry={disableInquiry} />
                ) : null}
                {filter.size > 0 && filteredActions.length === 0 ? (
                    <div className="text-muted-foreground m-10 text-center">
                        <p>No actions match the selected filter criteria.</p>
                        <Button onClick={clearFilter} variant="link" size="sm">
                            Clear Filter
                        </Button>
                    </div>
                ) : null}
                {filteredActions.map((a) =>
                    collapsedActions.has(a.id) ? (
                        <CollapsedActionMessage key={a.id} action={a} />
                    ) : (
                        <ActionMessage key={a.id} action={a} data-bw-actions />
                    ),
                )}
            </InfiniteScroll>
        </AutoScrollContainer>
    );
};
