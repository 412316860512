import { use } from "react";

import { ColumnSheetContext } from "@/components/document-table/columns/context";

export const useColumnSheetContext = <T,>(
    selector: (s: ColumnSheetContext) => T,
): T => {
    const ctx = use(ColumnSheetContext);
    return selector(ctx);
};
