import { isAfter } from "date-fns";
import { useEffect, useState } from "react";
import useSWR from "swr";

import { useApi } from "@/hooks/use-api";
import { useConfig } from "@/hooks/use-config";
import { isEmptyOrNull } from "@/utils/string-helpers";

let deployedAt: string | null = null;

export const useAppOutdated = () => {
    const api = useApi();
    const config = useConfig();
    const [isOutdated, setIsOutdated] = useState(false);
    const { data } = useSWR(
        ["app_version"],
        async () => (config.isDev ? undefined : await api.app_version()),
        {
            refreshInterval: config.isDev ? 0 : 30_000,
            refreshWhenHidden: true,
        },
    );

    useEffect(() => {
        if (isEmptyOrNull(data?.deployed_at)) {
            return;
        }
        // first fetch
        if (deployedAt === null) {
            deployedAt = data.deployed_at;
        } else if (isAfter(data.deployed_at, deployedAt)) {
            // new, later deployed_at date
            setIsOutdated(true);
        }
    }, [data]);

    return isOutdated;
};
