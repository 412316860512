import { Slash } from "lucide-react";
import { Suspense } from "react";
import { Link, useMatches } from "react-router-dom";

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Skeleton } from "@/components/ui/skeleton";
import { getMatchTitle, matchHasTitle } from "@/utils/breadcrumbs";

export const PageBreadcrumb = () => {
    const crumbs = useMatches().filter(matchHasTitle);
    return (
        <Breadcrumb>
            <BreadcrumbList>
                {crumbs.flatMap((m, i, arr) => [
                    i !== 0 ? (
                        <BreadcrumbSeparator key={`sep_${m.pathname}`}>
                            <Slash className="size-3.5 -rotate-12" />
                        </BreadcrumbSeparator>
                    ) : null,
                    arr.length - 1 === i ? (
                        <BreadcrumbPage
                            key={m.pathname}
                            className="font-medium"
                        >
                            <Suspense
                                fallback={<Skeleton className="h-3 w-44" />}
                            >
                                {getMatchTitle(m)}
                            </Suspense>
                        </BreadcrumbPage>
                    ) : (
                        <BreadcrumbItem key={m.pathname}>
                            <Suspense
                                fallback={<Skeleton className="h-3 w-44" />}
                            >
                                <BreadcrumbLink asChild>
                                    <Link
                                        to={m.pathname}
                                        className="font-medium underline-offset-2 hover:underline"
                                    >
                                        {getMatchTitle(m)}
                                    </Link>
                                </BreadcrumbLink>
                            </Suspense>
                        </BreadcrumbItem>
                    ),
                ])}
            </BreadcrumbList>
        </Breadcrumb>
    );
};
