import { Copy } from "lucide-react";
import useSWR from "swr";

import { CopyToClipboard } from "@/components/admin/copy-to-clipboard";
import { Copyright } from "@/components/copyright";
import { Button } from "@/components/ui/button";
import { useApi } from "@/hooks/use-api";
import { useConfig } from "@/hooks/use-config";
import { usePageTitle } from "@/hooks/use-page-title";
import { formatDateTime } from "@/utils/time";

export const AboutSettings = () => {
    usePageTitle(["About", "Settings"]);
    const api = useApi();
    const config = useConfig();
    const { data } = useSWR(
        ["app_version"],
        async () => await api.app_version(),
        { suspense: true },
    );

    const copyString = Object.entries(data)
        .map(([key, value]) => `${key}: ${value}`)
        .concat([`client_version: ${config.sha}`])
        .join("\n");

    return (
        <div className="col-span-2 space-y-8">
            <h1 className="text-headline text-2xl font-medium">About</h1>
            <div className="space-y-10">
                <Copyright className="text-base" />
                <div className="space-y-4 text-sm">
                    <p className="flex flex-col gap-1">
                        <span className="text-muted-foreground">
                            Deployed at:
                        </span>
                        <span className="font-mono">
                            {data.deployed_at
                                ? formatDateTime(data.deployed_at)
                                : "unknown"}
                        </span>
                    </p>
                    <p className="flex flex-col gap-1">
                        <span className="text-muted-foreground">
                            Deployment version:
                        </span>
                        <span className="font-mono">
                            {data.deployed_sha ?? "unknown"}
                        </span>
                    </p>
                    <p className="flex flex-col gap-1">
                        <span className="text-muted-foreground">Built at:</span>
                        <span className="font-mono">
                            {data.built_at
                                ? formatDateTime(data.built_at)
                                : "unknown"}
                        </span>
                    </p>
                    <p className="flex flex-col gap-1">
                        <span className="text-muted-foreground">
                            Server version:
                        </span>
                        <span className="font-mono">
                            {data.server_sha ?? "unknown"}
                        </span>
                    </p>
                    <p className="flex flex-col gap-1">
                        <span className="text-muted-foreground">
                            Client version:
                        </span>
                        <span className="font-mono">
                            {config.sha ?? "unknown"}
                        </span>
                    </p>
                    <CopyToClipboard value={copyString} asChild>
                        <Button variant="secondary" size="sm" className="gap-2">
                            <Copy className="size-4" />
                            Copy Version Information
                        </Button>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    );
};
