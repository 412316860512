import { ComponentPropsWithoutRef } from "react";
import { useShallow } from "zustand/react/shallow";

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { getState, hashState, State, useAnalyze } from "@/hooks/use-analyze";
import { useGridView } from "@/hooks/use-grid-view-context";

const getStates = (s: State, states: State[] = []): State[] =>
    s.previousState === undefined
        ? states
        : getStates(s.previousState, [s, ...states]);

export const Item = ({ state, asPage }: { state: State; asPage?: boolean }) => {
    const Comp = asPage ? BreadcrumbPage : BreadcrumbLink;
    const setState = useAnalyze((s) => s.setState);
    const documents = useGridView((s) => s.documents);
    const handleClick = asPage ? undefined : () => setState(state);
    if (state.document_id) {
        return (
            <BreadcrumbItem>
                <Comp
                    onClick={handleClick}
                    className="font-headline text-xl font-bold"
                >
                    {documents.get(state.document_id)?.title ?? "Document"}
                </Comp>
            </BreadcrumbItem>
        );
    }
    if (state.synthesis) {
        return (
            <BreadcrumbItem>
                <Comp
                    onClick={handleClick}
                    className="font-headline text-xl font-bold"
                >
                    Synthesis
                </Comp>
            </BreadcrumbItem>
        );
    }
    if (state.report) {
        return (
            <BreadcrumbItem>
                <Comp
                    onClick={handleClick}
                    className="font-headline text-xl font-bold"
                >
                    Report
                </Comp>
            </BreadcrumbItem>
        );
    }
    return null;
};

export const LeftPanelHeader = (props: ComponentPropsWithoutRef<"nav">) => {
    const state = useAnalyze(useShallow((s) => getState(s)));
    const states = getStates(state);

    if (states.length === 0) return null;

    return (
        <Breadcrumb {...props}>
            <BreadcrumbList>
                {states.flatMap((state, i, arr) => {
                    const hash = hashState(state);
                    return i < arr.length - 1
                        ? [
                              <Item key={hash} state={state} />,
                              <BreadcrumbSeparator key={`s_${hash}`} />,
                          ]
                        : [<Item key={hash} state={state} asPage />];
                })}
            </BreadcrumbList>
        </Breadcrumb>
    );
};
