import { emptyFunction } from "@/utils/empty-function";

import { useConfig } from "./use-config";
import { useDevMode } from "./use-dev-mode";

export type LogFn = (...args: unknown[]) => void;

export const log: LogFn = (...args) => {
    console.log(...args);
};

export const useLogger = (): LogFn => {
    const { isDev } = useConfig();
    const [devMode] = useDevMode();

    if (isDev || devMode) return log;
    return emptyFunction;
};
