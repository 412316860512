import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

import { useConfig } from "@/hooks/use-config";

let sentryCache: ReturnType<typeof Sentry.init> | undefined;
const configureSentry = (
    isDev: boolean,
    sentryEnv: string,
    sentryDsn: string,
) => {
    if (sentryCache === undefined && !isDev && sentryDsn) {
        sentryCache = Sentry.init({
            dsn: sentryDsn,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                Sentry.replayIntegration({
                    maskAllText: false,
                    maskAllInputs: true,
                }),
            ],
            tracesSampleRate: 1.0,
            tracePropagationTargets: [
                /^https:\/\/api\.staging\.brightwave\.io\//,
                /^https:\/\/api\.brightwave\.io\//,
            ],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            environment: sentryEnv,
        });
    }
};

export const SentryConfig = () => {
    const { isDev, sentryEnv, sentryDsn } = useConfig();
    useEffect(() => {
        configureSentry(isDev, sentryEnv, sentryDsn);
    }, [isDev, sentryEnv, sentryDsn]);
    return null;
};
