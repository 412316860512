/*
 * MUST mirror the Feature Flag name on Launch Darkly
 */
export enum FeatureFlagBool {
    ACTIONS_WITHOUT_INQUIRY = "ACTIONS_WITHOUT_INQUIRY",
    CHAT_ENABLED = "CHAT_ENABLED",
    CLEAR_CHAT = "CLEAR_CHAT",
    CUSTOM_SYNTHESIS_ENABLED = "CUSTOM_SYNTHESIS_ENABLED",
    ENABLE_DOC_AND_TXT_UPLOAD = "ENABLE_DOC_AND_TXT_UPLOAD",
    ENABLE_SPREADSHEET_UPLOAD = "ENABLE_SPREADSHEET_UPLOAD",
    FORCE_BOOLEAN_FINDING_TO_FALSE = "FORCE_BOOLEAN_FINDING_TO_FALSE",
    FULLSTORY_ENABLED = "FULLSTORY_ENABLED",
    REGENERATE_SYNTHESIS = "REGENERATE_SYNTHESIS",
    REPORT_V0_ENABLED = "REPORT_V0_ENABLED",
    SYNTHESIS_ENABLED = "SYNTHESIS_ENABLED",
}

export enum FeatureFlagNumber {
    REPORT_MAX_DOCUMENTS = "REPORT_MAX_DOCUMENTS",
}
