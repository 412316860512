import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRight } from "lucide-react";
import { HTMLAttributes } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
} from "@/components/ui/form";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { UseAsyncState } from "@/hooks/use-async-state";
import { cn } from "@/lib/utils";
import { capitalize } from "@/utils/string-helpers";

const TYPES = ["summary"] as const;

const formSchema = z.object({
    override_prompt: z.boolean(),
    type: z.enum(TYPES),
    prompt: z.string(),
});

export type FormSchema = z.infer<typeof formSchema>;

interface Props extends HTMLAttributes<HTMLFormElement> {
    action: UseAsyncState<[FormSchema], unknown>;
}

export const SynthesisForm = ({ action, className, ...props }: Props) => {
    const form = useForm<FormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            override_prompt: false,
            type: "summary",
            prompt: "",
        },
    });

    const handleSubmit = form.handleSubmit(action.submit);

    return (
        <Form {...form}>
            <form
                {...props}
                onSubmit={handleSubmit}
                className={cn("space-y-4 pb-2", className)}
            >
                <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Synthesis Type</FormLabel>
                            <Select
                                defaultValue={field.value}
                                onValueChange={field.onChange}
                            >
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {TYPES.map((type) => (
                                        <SelectItem key={type} value={type}>
                                            {capitalize(type)}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="prompt"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Prompt</FormLabel>
                            <FormControl>
                                <Textarea
                                    placeholder="Describe your synthesis"
                                    {...field}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="override_prompt"
                    render={({ field }) => (
                        <FormItem className="flex min-h-10 items-center gap-2 space-y-0">
                            <FormControl>
                                <Checkbox
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                />
                            </FormControl>
                            <FormLabel>Override Prompt</FormLabel>
                        </FormItem>
                    )}
                />
                <div className="flex justify-end">
                    <Button variant="primary">
                        Create Synthesis
                        <ArrowRight className="ml-2 size-4" />
                    </Button>
                </div>
            </form>
        </Form>
    );
};
