import { Navigate } from "react-router-dom";

import { PageLoader } from "@/components/page-loader";
import { useConfig } from "@/hooks/use-config";

export const LoginContainer = () => {
    const { auth0 } = useConfig();
    if (auth0) {
        window.location.assign(
            `/api/auth/login?return_url=${encodeURIComponent(window.location.pathname)}`,
        );
        return <PageLoader />;
    }

    return <Navigate to="/login" replace />;
};
