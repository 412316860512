import useSWRImmutable from "swr/immutable";

import { FindingGroupLaunchStatus, Probe } from "@/api/types";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { useColumnSheetContext } from "@/components/document-table/columns/use-column-sheet";
import { Badge } from "@/components/ui/badge";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useColumnSheet } from "@/hooks/use-column-sheet";
import { useUser } from "@/hooks/use-user";
import { cn } from "@/lib/utils";
import { hasBetaAccess } from "@/utils/user";

export const SystemColumns = () => {
    const api = useApi();
    const addedProbeIDs = useColumnSheetContext((s) => s.addedProbeIDs);
    const isBetaUser = hasBetaAccess(useUser());
    const { data } = useSWRImmutable(
        "report/column-definitions",
        async () => await api.fetch_available_columns(),
        { suspense: true },
    );

    const availableProbes = data
        .filter(
            (c) =>
                isBetaUser ||
                c.launch_status === FindingGroupLaunchStatus.launched,
        )
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className="flex grow flex-col gap-1 overflow-y-scroll p-4">
            {availableProbes.map((probe) => (
                <SystemColumn
                    key={probe.id}
                    probe={probe}
                    added={addedProbeIDs.has(probe.id)}
                />
            ))}
        </div>
    );
};

const SystemColumn = (props: { probe: Probe; added: boolean }) => {
    const addColumn = useColumnSheetContext((s) => s.onSelect);
    const close = useColumnSheet((s) => s.close);
    const action = useAsyncState(async () => await addColumn(props.probe), {
        onSuccess: close,
    });

    return (
        <div
            className={cn(
                "border-input group hover:bg-muted flex min-h-14 items-center gap-2 rounded border px-4",
                action.isSubmitting && "animate-pulse",
                props.added && "opacity-50",
            )}
            role="button"
            onClick={!props.added ? action.submit : undefined}
        >
            <ProbeTypeIcon type={props.probe.type} className="mr-2 shrink-0" />
            <span className="font-headline grow text-left font-medium">
                {props.probe.name}
            </span>
            <LaunchStatusBadge status={props.probe.launch_status} />
        </div>
    );
};

const LaunchStatusBadge = (props: { status: FindingGroupLaunchStatus }) => {
    switch (props.status) {
        case FindingGroupLaunchStatus.deprecated:
        case FindingGroupLaunchStatus.launched:
            return null;
        case FindingGroupLaunchStatus.beta:
            return (
                <Badge variant="beta" className="text-green-500">
                    BETA
                </Badge>
            );
        case FindingGroupLaunchStatus.internal:
            return (
                <Badge
                    variant="outline"
                    className="border-destructive text-destructive"
                >
                    INTERNAL
                </Badge>
            );
        default:
            return props.status satisfies never;
    }
};
