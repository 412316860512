import { BrightwaveLogo } from "@/components/brightwave-logo";
import { Badge } from "@/components/ui/badge";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useDevMode } from "@/hooks/use-dev-mode";
import { usePageTitle } from "@/hooks/use-page-title";
import { useV3 } from "@/hooks/use-v3";

export const DevSettings = () => {
    usePageTitle(["Dev", "Settings"]);
    const [devMode, setDevMode] = useDevMode();
    const [v3, setV3] = useV3();

    return (
        <div className="col-span-2 space-y-4">
            <h1 className="text-headline text-2xl font-medium">Dev Settings</h1>
            <div className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                    <Label htmlFor="dev-mode" className="text-base">
                        Dev Mode
                    </Label>
                    <div className="text-sm text-gray-500">
                        Show internal tools and features
                        <Badge variant="cmd" className="ml-2 px-2">
                            &#x2318; &#x21e7; D
                        </Badge>
                    </div>
                </div>
                <Switch
                    id="dev-mode"
                    checked={devMode}
                    onCheckedChange={setDevMode}
                />
            </div>
            <div className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                    <Label
                        htmlFor="v3"
                        className="font-headline flex items-center gap-2 text-base font-black"
                    >
                        <BrightwaveLogo /> <span className="text-xl">V3</span>
                    </Label>
                    <div className="text-sm text-gray-500">Report Builder</div>
                </div>
                <Switch id="v3" checked={v3} onCheckedChange={setV3} />
            </div>
        </div>
    );
};
