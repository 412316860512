import { HTMLAttributes } from "react";

import { DocumentInfo } from "@/api/types";
import { DocumentHeader } from "@/components/document/document-header";
import { FullstoryRedacted } from "@/components/fullstory-redacted";
import { cn } from "@/lib/utils";

interface Props extends HTMLAttributes<HTMLDivElement> {
    info: DocumentInfo;
}

export const Document = ({ info, children, className, ...props }: Props) => (
    <FullstoryRedacted
        className={cn(
            "bg-background @container grow space-y-20 overflow-y-scroll py-10",
            className,
        )}
        {...props}
    >
        <DocumentHeader className="container space-y-10" info={info} />
        <div
            className="@container container space-y-20"
            data-bw-actions
            data-action-context={`document:${info.id}`}
        >
            {children}
        </div>
    </FullstoryRedacted>
);
