import {
    ChartGantt,
    Combine,
    FilePenLine,
    LayoutPanelTop,
    Merge,
    X,
} from "lucide-react";
import { useShallow } from "zustand/react/shallow";

import { FindingGroupType, Probe } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { Debugger } from "@/components/debug/debugger";
import {
    FormSchema,
    SynthesisForm,
} from "@/components/document-table/synthesis/synthesis-form";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useAnalyze } from "@/hooks/use-analyze";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { useGridView } from "@/hooks/use-grid-view-context";
import { wrapStopPropagation } from "@/utils/dom";
import { notEmptyOrNull } from "@/utils/string-helpers";

export const SynthesisTrigger = ({ probe }: { probe: Probe }) => {
    const customSynthesisEnabled = useFeatureFlagBool(
        FeatureFlagBool.CUSTOM_SYNTHESIS_ENABLED,
    );
    const [dialogOpen, dialogActions] = useBoolean();

    const openSynthesis = useAnalyze((s) => s.openSynthesis);

    const api = useApi();
    const message_id = useGridView((s) => s.report.id);
    const finding_ids = useGridView(
        useShallow((s) =>
            Array.from(s.finding_groups.values())
                .filter((fg) => fg.probe.id === probe?.id)
                .map((fg) => fg.id),
        ),
    );
    const createSummaryAction = useAsyncState(
        async () =>
            await api.create_synthesis(
                message_id,
                finding_ids,
                FindingGroupType.summary,
            ),
        { onSuccess: (info) => openSynthesis({ info, finding_ids }) },
    );

    const createDedupeAction = useAsyncState(
        async () =>
            await api.create_synthesis(
                message_id,
                finding_ids,
                FindingGroupType.dedupe,
            ),
        { onSuccess: (info) => openSynthesis({ info, finding_ids }) },
    );

    const createTimelineAction = useAsyncState(
        async () =>
            await api.create_synthesis(
                message_id,
                finding_ids,
                FindingGroupType.timeline,
            ),
        { onSuccess: (info) => openSynthesis({ info, finding_ids }) },
    );

    const createStructuredSummaryAction = useAsyncState(
        async () =>
            await api.create_synthesis(
                message_id,
                finding_ids,
                FindingGroupType.structured_summary,
            ),
        { onSuccess: (info) => openSynthesis({ info, finding_ids }) },
    );

    const createCustomAction = useAsyncState(
        async (data: FormSchema) =>
            await api.create_synthesis(
                message_id,
                finding_ids,
                data.type as FindingGroupType,
                {
                    force: true,
                    override_prompt: data.override_prompt,
                    custom_input: notEmptyOrNull(data.prompt)
                        ? data.prompt
                        : undefined,
                },
            ),
        {
            onSuccess: (info) => {
                dialogActions.close();
                openSynthesis({ info, finding_ids });
            },
        },
    );

    return (
        <>
            <AsyncButton
                variant="ghost"
                size="sm"
                type="button"
                className="w-full justify-start"
                action={createSummaryAction}
            >
                <Combine className="mr-2 size-4" />
                Synthesis
            </AsyncButton>
            <Debugger>
                <AsyncButton
                    variant="ghost"
                    size="sm"
                    type="button"
                    className="w-full justify-start hover:bg-blue-100 hover:text-blue-700"
                    action={createDedupeAction}
                >
                    <Merge className="mr-2 size-4" />
                    Dedupe Synthesis
                </AsyncButton>
                <AsyncButton
                    variant="ghost"
                    size="sm"
                    type="button"
                    className="w-full justify-start hover:bg-blue-100 hover:text-blue-700"
                    action={createTimelineAction}
                >
                    <ChartGantt className="mr-2 size-4" />
                    Timeline Synthesis
                </AsyncButton>
                <AsyncButton
                    variant="ghost"
                    size="sm"
                    type="button"
                    className="w-full justify-start hover:bg-blue-100 hover:text-blue-700"
                    action={createStructuredSummaryAction}
                >
                    <LayoutPanelTop className="mr-2 size-4" />
                    Structured Synthesis
                </AsyncButton>
            </Debugger>
            {customSynthesisEnabled && (
                <>
                    <Button
                        variant="ghost"
                        size="sm"
                        type="button"
                        className="w-full justify-start"
                        onClick={wrapStopPropagation(dialogActions.open)}
                    >
                        <FilePenLine className="size-4" />
                        Custom Synthesis
                    </Button>
                    <Dialog open={dialogOpen} onOpenChange={dialogActions.set}>
                        <DialogContent withCloseButton={false} className="p-2">
                            <DialogHeader className="flex flex-row items-center justify-between gap-2 space-y-0">
                                <DialogTitle className="pl-2">
                                    Create Synthesis
                                </DialogTitle>
                                <DialogDescription className="sr-only">
                                    Create a custom synthesis
                                </DialogDescription>
                                <DialogClose asChild>
                                    <Button variant="ghost" size="icon">
                                        <X className="size-4" />
                                    </Button>
                                </DialogClose>
                            </DialogHeader>
                            <SynthesisForm
                                action={createCustomAction}
                                className="px-2"
                            />
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </>
    );
};
