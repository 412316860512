import { nonNull } from "@/utils/fn";

export enum AsyncState {
    initial,
    queued,
    fetching,
    error,
    success,
}

export type AsyncValue<T> =
    | { state: AsyncState.initial }
    | { state: AsyncState.queued }
    | { state: AsyncState.fetching }
    | { state: AsyncState.success; value: T }
    | { state: AsyncState.error; error: Error };

export const createSuccess = <T>(value: T): AsyncValue<T> => ({
    state: AsyncState.success,
    value,
});

export const createError = <T>(error: Error): AsyncValue<T> => ({
    state: AsyncState.error,
    error,
});

export const getMaybeValue = <T>(
    async_value: AsyncValue<T> | null | undefined,
): T | null =>
    nonNull(async_value) && async_value.state === AsyncState.success
        ? async_value.value
        : null;

export const getAsyncValue = <T>(
    async_value: AsyncValue<T>,
    defaultValue: T,
): T => getMaybeValue(async_value) ?? defaultValue;

export const mergeAsyncValue = <T>(
    a: AsyncValue<T>,
    b: AsyncValue<T>,
    mergeFn: (a: T, b: T | null) => T,
): AsyncValue<T> => {
    if (a.state === AsyncState.success) {
        return {
            state: AsyncState.success,
            value: mergeFn(a.value, getMaybeValue(b)),
        };
    }
    return b;
};
