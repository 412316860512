import { BookOpenCheck, Search, StarIcon, X } from "lucide-react";
import { SyntheticEvent, useState } from "react";
import { toast } from "sonner";
import useSWR from "swr";

import { Probe, UUID } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { useColumnSheetContext } from "@/components/document-table/columns/use-column-sheet";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useColumnSheet } from "@/hooks/use-column-sheet";
import { cn } from "@/lib/utils";
import { notEmptyOrNull } from "@/utils/string-helpers";

const filterProbes = (
    probes: Probe[],
    searchQuery: string | null,
    onlyStarred: boolean,
): Probe[] => {
    let res = probes;
    if (onlyStarred === true) {
        res = res.filter((probe) => probe.starred);
    }
    if (notEmptyOrNull(searchQuery)) {
        res = res.filter(
            (probe) =>
                probe.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                probe.prompt?.toLowerCase().includes(searchQuery.toLowerCase()),
        );
    }
    return res;
};

export const ProbesLibrary = () => {
    const api = useApi();
    const [filter, setFilter] = useState<string>("starred");
    const [search, setSearch] = useState("");
    const { data: allProbes, mutate } = useSWR(
        ["probes"],
        async () => await api.fetch_probes(),
        { suspense: true, revalidateOnMount: true },
    );
    const addedProbeIDs = useColumnSheetContext((s) => s.addedProbeIDs);
    const onStarChange = useColumnSheetContext((s) => s.onStarChange);

    if (allProbes.length === 0) {
        return (
            <div className="m-4 flex grow flex-col items-center justify-center gap-8 rounded-lg border p-4">
                <span className="bg-muted rounded-full p-6">
                    <BookOpenCheck className="text-muted-foreground size-10" />
                </span>
                <div className="space-y-1 text-center">
                    <p className="font-semibold">Your library is empty</p>
                    <p className="text-sm">Start by creating a new column</p>
                </div>
            </div>
        );
    }

    const probes = filterProbes(allProbes, search, filter === "starred");

    const getStarredChangeHandler = (id: UUID) => (starred: boolean) => {
        mutate((probes) =>
            (probes ?? []).map((p) => (p.id === id ? { ...p, starred } : p)),
        );
        onStarChange(id, starred);
    };

    return (
        <>
            <Tabs value={filter} onValueChange={setFilter} className="px-4">
                <TabsList className="flex">
                    <TabsTrigger
                        value="starred"
                        className="flex flex-1 items-center gap-2"
                    >
                        <StarIcon className="size-4 fill-current" />
                        Favorites
                    </TabsTrigger>
                    <TabsTrigger value="all" className="flex-1">
                        All
                    </TabsTrigger>
                </TabsList>
            </Tabs>
            <label className="border-input bg-background ring-offset-background focus-within:ring-ring mx-4 flex min-h-10 items-center gap-2 rounded-md border pl-3 text-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:outline-hidden">
                <Search className="size-4" />
                <input
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="placeholder:text-muted-foreground grow outline-hidden"
                />
                <Button
                    variant="ghost-destructive"
                    size="icon-sm"
                    className={cn(!notEmptyOrNull(search) && "hidden")}
                    onClick={() => setSearch("")}
                >
                    <X className="size-4" />
                </Button>
            </label>
            <div className="flex grow flex-col gap-2 overflow-y-scroll p-4">
                {probes.map((probe) => (
                    <ProbeColumn
                        key={probe.id}
                        probe={probe}
                        added={addedProbeIDs.has(probe.id)}
                        onStarChanged={getStarredChangeHandler(probe.id)}
                    />
                ))}
                {filter === "starred" && probes.length === 0 ? (
                    <div className="flex grow flex-col items-center justify-center gap-8 rounded-lg border p-20">
                        <span className="bg-muted rounded-full p-6">
                            <StarIcon className="text-muted-foreground size-10" />
                        </span>
                        <div className="space-y-1 text-center">
                            <p className="font-semibold">No Favorites</p>
                            <p className="text-sm">
                                You can add an analysis to your favorites by
                                clicking on the column header in GridView
                            </p>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

const ProbeColumn = ({
    probe,
    added,
    onStarChanged,
}: {
    probe: Probe;
    added: boolean;
    onStarChanged?: (starred: boolean) => void;
}) => {
    const api = useApi();
    const addColumn = useColumnSheetContext((s) => s.onSelect);
    const close = useColumnSheet((s) => s.close);
    const addAction = useAsyncState(async () => await addColumn(probe), {
        onSuccess: close,
    });

    const starAction = useAsyncState(
        async (e: SyntheticEvent) => {
            e.stopPropagation();
            if (probe.starred) {
                await api.unstar_probe(probe.id);
            } else {
                await api.star_probe(probe.id);
            }
            return { ...probe, starred: !probe.starred };
        },
        {
            onSuccess: (probe) => {
                onStarChanged?.(probe.starred);
                toast.success(
                    probe.starred
                        ? `Added "${probe.name}" to Favorites`
                        : `Removed "${probe.name}" from Favorites`,
                );
            },
        },
    );

    return (
        <div
            className={cn(
                "border-input group hover:bg-muted flex min-h-14 shrink-0 items-center gap-3.5 rounded border px-4 py-2.5",
                addAction.isSubmitting && "animate-pulse",
                added && "opacity-50",
            )}
            role="button"
            onClick={!added ? addAction.submit : undefined}
        >
            <AsyncButton
                action={starAction}
                variant="ghost"
                size="icon-xs"
                className="shrink-0"
            >
                <StarIcon
                    className={cn("size-4", probe.starred && "fill-current")}
                />
            </AsyncButton>
            <div className="grow space-y-1">
                <div className="flex items-center gap-2">
                    <ProbeTypeIcon type={probe.type} className="shrink-0" />
                    <p className="font-headline font-medium">{probe.name}</p>
                </div>
                <p className="text-muted-foreground line-clamp-8 text-sm">
                    {probe.prompt}
                </p>
            </div>
        </div>
    );
};

export const ProbesLibrarySkeleton = ({
    showTabs = false,
}: {
    showTabs?: boolean;
}) => (
    <div className="space-y-4 p-4">
        <Skeleton className="h-10 w-full" />
        {showTabs && (
            <div className="border-input h-10 w-full rounded border" />
        )}
        {Array(10)
            .fill(1)
            .map((_, i) => (
                <div
                    className="flex min-h-9 items-center gap-4 rounded border p-4"
                    key={i}
                >
                    <Skeleton className="size-4" />
                    <Skeleton className="h-3 w-1/2" />
                </div>
            ))}
    </div>
);
