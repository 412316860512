import { PropsWithChildren, useEffect, useRef } from "react";
import useSWRImmutable from "swr/immutable";
import { useStore } from "zustand";

import { FullDocumentCollection, Probe, UUID } from "@/api/types";
import { IncomingWebsocketEventType } from "@/api/ws/websocket-types";
import { GridViewContext } from "@/context/grid-view-context";
import { useApi } from "@/hooks/use-api";
import { useOnUnmount } from "@/hooks/use-on-unmount";
import { useWebsocket } from "@/hooks/use-websocket";
import {
    createGridViewStore,
    GridViewStore,
    VersionedState,
} from "@/stores/grid-view";
import { createInitialAsyncFindingGroup } from "@/utils/finding-group";
import { nonNull } from "@/utils/fn";

const ProdviderImpl = ({
    report,
    persistedClientState,
    availableColumns,
    ...props
}: PropsWithChildren<{
    report: FullDocumentCollection;
    persistedClientState: VersionedState<Record<string, unknown>> | null;
    availableColumns: Probe[];
}>) => {
    const api = useApi();

    // eslint-disable-next-line react-compiler/react-compiler
    const store = useRef<GridViewStore>(
        createGridViewStore({
            api,
            report,
            persistedClientState,
            availableColumns,
        }),
    ).current;

    // update report on SWR revalidate
    const updateReport = useStore(store, (s) => s.updateReport);
    useEffect(() => {
        updateReport(report);
    }, [report]);

    // update document status and finding groups from WS
    const updateDocumentStatus = useStore(store, (s) => s.updateDocumentStatus);
    const addFindingGroup = useStore(store, (s) => s.addFindingGroup);
    const updateFindingGroup = useStore(store, (s) => s.updateFindingGroup);

    useWebsocket((ws) => {
        ws.on(
            IncomingWebsocketEventType.document_status_updated,
            ({ document_id, status }) =>
                updateDocumentStatus(document_id, status),
        );
        ws.on(
            IncomingWebsocketEventType.finding_group_created,
            ({ document_id, ...finding_group }) => {
                if (document_id != null) {
                    addFindingGroup({
                        document_id,
                        ...finding_group,
                    });
                }
            },
        );
        ws.on(
            IncomingWebsocketEventType.finding_group_updated,
            ({ document_id, ...finding_group }) => {
                if (document_id != null) {
                    updateFindingGroup({ document_id, ...finding_group });
                }
            },
        );
    });

    // load initial finding groups using the batch API
    useSWRImmutable(
        [report.id, "full_finding_groups"],
        async ([reportID]) => {
            const state = store.getState();
            const documentIDs = Array.from(
                state.documents_to_finding_groups.entries(),
            )
                .filter(([_, findingGroupIDs]) =>
                    findingGroupIDs
                        .map((fg_id) => state.finding_groups.get(fg_id))
                        .filter(nonNull),
                )
                .map(([documentID]) => documentID);
            return await api.fetch_document_finding_groups(
                reportID,
                documentIDs,
            );
        },
        {
            revalidateOnMount: true,
            onSuccess: (findingGroups) => {
                for (let i = findingGroups.length - 1; i >= 0; --i) {
                    updateFindingGroup(
                        createInitialAsyncFindingGroup(findingGroups[i]),
                    );
                }
            },
            // TODO: handle error
        },
    );

    return <GridViewContext value={store} {...props} />;
};

export const GridViewContextProvider = ({
    messageID,
    ...props
}: PropsWithChildren<{ messageID: UUID }>) => {
    const api = useApi();
    const { data: asyncProps, mutate } = useSWRImmutable(
        [messageID, "grid_view"],
        async ([id]) => ({
            report: await api.fetch_report(id),
            persistedClientState: await api.fetch_grid_view_state(id),
        }),
        { suspense: true },
    );
    const { data: availableColumns } = useSWRImmutable(
        "report/column-definitions",
        async () => await api.fetch_available_columns(),
        { suspense: true },
    );

    // clear cache to force re-retch on mount without rendering stale data
    useOnUnmount(() => mutate(undefined, { revalidate: false }));

    return (
        <ProdviderImpl
            key={messageID}
            availableColumns={availableColumns}
            {...asyncProps}
            {...props}
        />
    );
};
