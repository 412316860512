import { HTMLAttributes, MouseEvent } from "react";

import { UUID } from "@/api/types";
import { HoverTooltip } from "@/components/hover-tooltip";
import { useClipboard } from "@/hooks/use-clipboard";
import { useDevMode } from "@/hooks/use-dev-mode";
import { cn } from "@/lib/utils";
import { notEmptyOrNull } from "@/utils/string-helpers";

interface DebugIDProps extends HTMLAttributes<HTMLSpanElement> {
    label?: string;
    id: UUID;
    forceShow?: boolean;
    block?: boolean;
}

export const DebugID = ({
    label,
    id,
    forceShow = false,
    block = false,
    className,
    ...props
}: DebugIDProps) => {
    const clipboard = useClipboard({ timeout: 1000 });
    const [devMode] = useDevMode();
    if (!devMode && !forceShow) {
        return null;
    }
    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        clipboard.copy(id);
    };

    return (
        <span
            className={cn(
                "font-mono text-[10px] text-gray-500",
                className,
                block && "block",
            )}
            {...props}
        >
            {notEmptyOrNull(label) ? `${label}: ` : null}
            <HoverTooltip tooltip="copied" open={clipboard.copied}>
                <span
                    onClick={handleClick}
                    className="cursor-pointer hover:underline"
                >
                    {id}
                </span>
            </HoverTooltip>
        </span>
    );
};
