import {
    FindingGroupLaunchStatus,
    FindingGroupType,
    ProbeType,
    SynthesisType,
    WithDocumentInfo,
} from "@/api/types";
import { Entity, UUID } from "@/types/base";

export enum ProjectComponentRole {
    system = "system",
    user = "user",
}

export enum ProjectComponentType {
    document_collection = "document_collection",
    report = "report",
}

export interface ProjectBase {
    account_id: UUID;
    owner_id: UUID;
    title: string | null;
}

export interface CreateNewProject extends ProjectBase {
    id: UUID;
}

export interface Project extends ProjectBase {
    id: UUID;
    created_at: string;
}

export interface ProjectComponentBase {
    project_id: UUID;
    type: ProjectComponentType;
    role: ProjectComponentRole;
    text: string | null;
    title: string | null;
}

export interface DocumentCollectionBase extends ProjectComponentBase {
    type: ProjectComponentType.document_collection;
    author_id: UUID;
}

export interface CreateDocumentCollection extends DocumentCollectionBase {
    blueprint_version_id: UUID | null;
}

export interface DocumentCollection extends DocumentCollectionBase {
    id: UUID;
    blueprint_version_id: UUID;
    created_at: string;
}

export interface ReportBase extends ProjectComponentBase {
    type: ProjectComponentType.report;
    author_id: UUID;
    response_to: UUID;
}

export interface CreateReport extends ReportBase {
    blueprint_version_id: UUID | null;
    thread_id: UUID;
}

export interface Report extends ReportBase {
    id: UUID;
    latest: boolean;
    thread_id: UUID;
    blueprint_version_id: UUID;
    created_at: string;
}

export type ProjectComponent = DocumentCollection | Report;

export interface AnalysisOrSynthesisBase extends Entity {
    schema_id: UUID;
    owner_id: UUID;
    launch_status: FindingGroupLaunchStatus;
    finding_group_type: FindingGroupType;
    name: string;
    prompt: string | null;
}

export interface AnalysisProbeSchema extends AnalysisOrSynthesisBase {
    analysis_or_synthesis: "analysis";
    analysis_type: FindingGroupType;
    type: ProbeType;
    is_analysis: true;
}
export interface SynthesisProbeSchema extends AnalysisOrSynthesisBase {
    analysis_or_synthesis: "synthesis";
    synthesis_type: SynthesisType;
    contexts: AnalysisProbeSchema[];
    is_analysis: false;
}

export interface OrderedAnalysisProbe extends AnalysisProbeSchema {
    order: number;
}

export interface OrderedSynthesisProbe extends SynthesisProbeSchema {
    order: number;
}
export type OrderedProbe = OrderedAnalysisProbe | OrderedSynthesisProbe;

export interface Blueprint {
    owner_id: UUID;
    parent_version_id: UUID | null;
    version_id: UUID;
    thread_id: UUID;
    probes: OrderedProbe[];
    created_at: string;
}

export interface ProjectComponentInfoWithBlueprint<T = ProjectComponent> {
    info: T;
    blueprint: Blueprint;
    documents: WithDocumentInfo[];
}
