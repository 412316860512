import {
    FindingGroupType,
    DocumentFindingGroup,
    GenericFindingContent,
    FindingContentType,
} from "@/api/types";
import { FindingContent } from "@/components/document-table/finding-content";
import { Headline } from "@/components/headline";
import { Markdown } from "@/components/markdown";
import { Skeleton } from "@/components/ui/skeleton";
import { leadingZeros } from "@/utils/string-helpers";

export const DocumentFindingGroupContent = ({
    findingGroup,
}: {
    findingGroup: DocumentFindingGroup;
}) => {
    if (findingGroup.findings.length === 0) {
        return null;
    }

    switch (findingGroup.type) {
        case FindingGroupType.executive_summary:
            const keyTakeaways = findingGroup.findings.filter(
                (f) =>
                    f.content_type === FindingContentType.short_text ||
                    f.content_type === FindingContentType.list_item,
            );
            return (
                <div className="grid gap-x-14 gap-y-10 @3xl:grid-cols-2 @5xl:gap-x-32">
                    <div className="space-y-8">
                        <Headline level={4} separator>
                            Synthesis
                        </Headline>
                        {findingGroup.findings
                            .filter(
                                (f) =>
                                    f.content_type ===
                                    FindingContentType.long_text,
                            )
                            .map((finding) => (
                                <FindingContent
                                    key={finding.id}
                                    finding={finding}
                                    className="text-base"
                                />
                            ))}
                        {findingGroup.unstructured_result && (
                            <Markdown className="basis-full text-base">
                                {findingGroup.unstructured_result}
                            </Markdown>
                        )}
                    </div>
                    {keyTakeaways.length > 0 && (
                        <div className="space-y-8">
                            <Headline level={4} separator>
                                Key Takeaways
                            </Headline>
                            <ExecutiveSummaryKeyTakeaways
                                findings={keyTakeaways}
                            />
                        </div>
                    )}
                </div>
            );
        default:
            return null;
    }
};

const ExecutiveSummaryKeyTakeaways = (props: {
    findings: GenericFindingContent[];
}) => {
    if (props.findings.length === 0) {
        return null;
    }
    return (
        <ul className="space-y-8">
            {props.findings.map((finding, i) => (
                <li key={finding.id} className="flex gap-4">
                    <span className="font-headline text-2xl font-black">
                        {leadingZeros(i + 1, 2)}
                    </span>
                    <FindingContent finding={finding} className="text-base" />
                </li>
            ))}
        </ul>
    );
};

export const DocumentFindingSkeleton = () => (
    <div className="grid gap-x-14 gap-y-10 @3xl:grid-cols-2 @5xl:gap-x-32">
        <div className="space-y-8">
            <Headline level={4} separator>
                Synthesis
            </Headline>
            <div className="space-y-4">
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-1/2" />
            </div>
            <div className="space-y-4">
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-full" />
                <Skeleton className="h-3 w-1/2" />
            </div>
        </div>
        <div className="space-y-8">
            <Headline level={4} separator>
                Key Takeaways
            </Headline>
            <ul className="space-y-8">
                <li className="flex items-center gap-4">
                    <Skeleton className="h-8 w-10" />
                    <div className="grow space-y-4">
                        <Skeleton className="h-3 w-full" />
                        <Skeleton className="h-3 w-1/2" />
                    </div>
                </li>
                <li className="flex items-center gap-4">
                    <Skeleton className="h-8 w-10" />
                    <div className="grow space-y-4">
                        <Skeleton className="h-3 w-full" />
                        <Skeleton className="h-3 w-1/2" />
                    </div>
                </li>
                <li className="flex items-center gap-4">
                    <Skeleton className="h-8 w-10" />
                    <div className="grow space-y-4">
                        <Skeleton className="h-3 w-full" />
                        <Skeleton className="h-3 w-1/2" />
                    </div>
                </li>
                <li className="flex items-center gap-4">
                    <Skeleton className="h-8 w-10" />
                    <div className="grow space-y-4">
                        <Skeleton className="h-3 w-full" />
                        <Skeleton className="h-3 w-1/2" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
);
