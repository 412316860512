import {
    FullStory as FS,
    isInitialized as isFSInitialized,
} from "@fullstory/browser";
import { PropsWithChildren, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { toast } from "sonner";
import useSWR from "swr";

import { HttpStatusCode } from "@/api/http-status-codes";
import { BrightwaveAPI, HttpError } from "@/api/rest";
import { User } from "@/api/types";
import {
    WebsocketClient,
    WebsocketClientEvent,
} from "@/api/ws/websocket-client";
import { AppContext } from "@/context/app-context";
import { useConfig } from "@/hooks/use-config";
import { useDevMode } from "@/hooks/use-dev-mode";
import { useLogger } from "@/hooks/use-logger";
import { useV3 } from "@/hooks/use-v3";
import {
    AsyncState,
    AsyncValue,
    createError,
    createSuccess,
    getMaybeValue,
} from "@/utils/async-value";
import { nonNull } from "@/utils/fn";
import { getCachedWebsocket } from "@/utils/get-cached-websocket";
import { isUserSuperAdmin } from "@/utils/user";

export const AppContextProvider = (props: PropsWithChildren) => {
    const config = useConfig();
    const [, setDevMode] = useDevMode();
    const [, setV3] = useV3();
    const api = new BrightwaveAPI();
    const [websocket, setWebsocket] = useState<WebsocketClient | null>(null);

    // redirect to logout
    const logout = () => {
        window.location.assign("/api/auth/logout");
    };

    const { data: asyncUser, mutate } = useSWR<AsyncValue<User>>(
        ["user_info"],
        async () => {
            try {
                return createSuccess(await api.fetch_user_info());
            } catch (e) {
                if (e instanceof HttpError) {
                    return createError<User>(e);
                }
            }
            return createError<User>(new Error("unknown error"));
        },
        {
            suspense: true,
            onSuccess: (val) => {
                // user was previously logged in then logout
                if (
                    val.state === AsyncState.error &&
                    val.error instanceof HttpError &&
                    val.error.status === HttpStatusCode.Unauthorized &&
                    nonNull(getMaybeValue(asyncUser))
                ) {
                    logout();
                }
            },
        },
    );

    const user = getMaybeValue(asyncUser);

    const login = () => mutate();

    // subscribe & unsubscribe to 401 errors and auto-logout
    useEffect(() => api.onNotAuthenticated(logout), [logout]);

    // initialize websocket connection
    useEffect(() => {
        getCachedWebsocket().then(setWebsocket);
    }, []);

    // update ws logger
    const logger = useLogger();
    useEffect(() => {
        websocket?.setLogger(logger);
    }, [websocket, logger]);

    // logout when socket is closed due to auth revocation
    useEffect(() => {
        if (websocket) {
            websocket.addEventListener(
                WebsocketClientEvent.AUTH_REVOKED,
                logout,
            );
            return () => {
                websocket.removeEventListener(
                    WebsocketClientEvent.AUTH_REVOKED,
                    logout,
                );
            };
        }
    }, [logout, websocket]);

    useHotkeys(
        "mod+shift+d",
        (e) => {
            if (user && isUserSuperAdmin(user)) {
                e.preventDefault();
                setDevMode((dm) => {
                    toast.info(dm ? "DevMode disabled" : "DevMode enabled");
                    return !dm;
                });
            }
        },
        { enableOnFormTags: true },
    );

    useHotkeys(
        "mod+shift+b",
        (e) => {
            if (user && isUserSuperAdmin(user)) {
                e.preventDefault();
                setV3((v3) => {
                    toast.info(v3 ? "V3 disabled" : "V3 enabled");
                    return !v3;
                });
            }
        },
        { enableOnFormTags: true },
    );

    // Identify fullstory if enabled and initialized
    const fsInitialized = isFSInitialized();
    useEffect(() => {
        // Note: the FS flag is served from the BE, which is backed by LaunchDarkly.
        // This avoids any context invalidations once the FE LaunchDarkly loads.
        if (fsInitialized && user && config.featureFlags.FULLSTORY_ENABLED) {
            FS("setIdentity", {
                uid: user.id,
                properties: {
                    displayName: user.id,
                    userType: user.user_type,
                    accountId: user.account_id,
                    accountType: user.account_type,
                    paid: user.paid,
                    status: user.status,
                    env: config.env,
                    isBw: user.username?.endsWith("brightwave.io"),
                },
            });
        }
    }, [
        user?.id,
        config.featureFlags.FULLSTORY_ENABLED,
        config.env,
        fsInitialized,
    ]);

    return (
        <AppContext
            value={{
                api,
                websocket,
                user: user ?? null,
                mutateUser: mutate,
                login,
                logout,
            }}
        >
            {props.children}
        </AppContext>
    );
};
