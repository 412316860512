import {
    ChevronsUpDownIcon,
    LogOutIcon,
    SettingsIcon,
    ShieldIcon,
    UserIcon,
} from "lucide-react";
import { Link } from "react-router-dom";

import { AuthLevel } from "@/api/types";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    useSidebar,
} from "@/components/ui/sidebar";
import { AuthGate } from "@/gates/auth-gate";
import { useAppContext } from "@/hooks/use-app-context";
import { useUser } from "@/hooks/use-user";
import { getFirstLastName } from "@/utils/user";

export const NavUser = () => {
    const { isMobile } = useSidebar();
    const user = useUser();
    const logout = useAppContext((s) => s.logout);
    return (
        <SidebarMenu>
            <SidebarMenuItem>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <SidebarMenuButton
                            size="lg"
                            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                        >
                            <Avatar className="size-8">
                                <AvatarFallback className="">
                                    <UserIcon className="size-4" />
                                </AvatarFallback>
                            </Avatar>
                            <div className="grid flex-1 text-left text-sm leading-tight">
                                <span className="truncate font-semibold">
                                    {getFirstLastName(user)}
                                </span>
                                <span className="text-muted-foreground truncate text-xs">
                                    {user.username}
                                </span>
                            </div>
                            <ChevronsUpDownIcon className="size-4" />
                        </SidebarMenuButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className="w-[var(--radix-dropdown-menu-trigger-width)] min-w-56 rounded-lg"
                        side={isMobile ? "bottom" : "right"}
                        align="end"
                        sideOffset={4}
                    >
                        <DropdownMenuLabel className="p-0 font-normal">
                            <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                                <Avatar>
                                    <AvatarFallback>
                                        <UserIcon className="size-4" />
                                    </AvatarFallback>
                                </Avatar>
                                <div className="grid flex-1 text-left text-sm leading-tight">
                                    <span className="truncate font-semibold">
                                        {getFirstLastName(user)}
                                    </span>
                                    <span className="text-muted-foreground truncate text-xs">
                                        {user.username}
                                    </span>
                                </div>
                            </div>
                        </DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem className="gap-2" asChild>
                            <Link to="/v3/settings/profile">
                                <SettingsIcon className="size-4" />
                                <span>Settings</span>
                            </Link>
                        </DropdownMenuItem>
                        <AuthGate min={AuthLevel.superadmin}>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                                className="gap-2 focus:bg-orange-100 focus:text-orange-800"
                                asChild
                            >
                                <Link to="/v3/admin">
                                    <ShieldIcon className="size-4" />
                                    <span>Admin</span>
                                </Link>
                            </DropdownMenuItem>
                        </AuthGate>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                            className="gap-2 focus:bg-red-100 focus:text-red-800"
                            onClick={logout}
                        >
                            <LogOutIcon className="size-4" />
                            <span>Sign out</span>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </SidebarMenuItem>
        </SidebarMenu>
    );
};
