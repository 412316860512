import { Loader2Icon } from "lucide-react";
import { HTMLAttributes } from "react";
import { useParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import { validate as validateUUID } from "uuid";

import { CreateSynthesisProbeSchema } from "@/api/rest";
import { AsyncFindingGroup, FindingGroupStatus } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { DetailedFinding } from "@/components/document-table/condensed/detailed-finding";
import { Headline } from "@/components/headline";
import { CreateSection } from "@/components/report-builder/create-section";
import { Button } from "@/components/ui/button";
import { useApi } from "@/hooks/use-api";
import { useAsyncState } from "@/hooks/use-async-state";
import { useBoolean } from "@/hooks/use-boolean";
import { cn } from "@/lib/utils";
import { NotFoundRoute } from "@/routes/not-found";
import { UUID } from "@/types/base";
import { AnalysisProbeSchema, SynthesisProbeSchema } from "@/types/report";
import { AsyncState } from "@/utils/async-value";
import { createAsyncFindingGroup } from "@/utils/finding-group";
import { capitalize } from "@/utils/string-helpers";

const ReportSection = (props: { finding: AsyncFindingGroup<unknown> }) => {
    switch (props.finding.status) {
        case FindingGroupStatus.processing:
            return (
                <>
                    <Loader2Icon className="size-4 animate-spin" />
                    Processing (you need to hit refresh because websockets are
                    not working yet)
                </>
            );
        case FindingGroupStatus.completed:
            switch (props.finding.findings.state) {
                case AsyncState.initial:
                case AsyncState.queued:
                case AsyncState.fetching:
                    return (
                        <>Loading {AsyncState[props.finding.findings.state]}</>
                    );
                case AsyncState.error:
                    return <p>Failed to load analysis</p>;
                case AsyncState.success:
                    return props.finding.findings.value.map((finding) => (
                        <DetailedFinding key={finding.id} finding={finding} />
                    ));
            }
        case FindingGroupStatus.failed:
        case FindingGroupStatus.cancelled:
            return <p>Failed or cancelled</p>;
    }
};

const ProbeDetails = ({
    probe,
    className,
    ...props
}: {
    probe: SynthesisProbeSchema | AnalysisProbeSchema;
} & HTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            className={cn("bg-background rounded border p-2", className)}
            {...props}
        >
            <p className="font-bold">{probe.name}</p>
            <p>
                {capitalize(probe.analysis_or_synthesis)}
                {" – "}
                {capitalize(
                    probe.is_analysis ? probe.type : probe.synthesis_type,
                )}
            </p>
            <p className="italic empty:hidden">{probe.prompt}</p>
            {!probe.is_analysis ? (
                <div className="mt-3">
                    {probe.contexts.map((p) => (
                        <ProbeDetails key={p.id} probe={p} />
                    ))}
                </div>
            ) : undefined}
        </div>
    );
};

const ReportImpl = (props: { project_id: UUID; report_id: UUID }) => {
    const api = useApi();
    const [isCreating, isCreatingActions] = useBoolean(false);
    const {
        data: [info, findings],
        mutate,
    } = useSWRImmutable(
        [props.project_id, props.report_id, "report"],
        async ([project_id, report_id]) =>
            await Promise.all([
                api.v3_fetch_report_info(project_id, report_id),
                api.v3_fetch_report_findings(project_id, report_id),
            ]),
        { suspense: true },
    );
    const addSectionAction = useAsyncState(
        async (data: CreateSynthesisProbeSchema) => {
            await api.v3_report_add_section(
                props.project_id,
                props.report_id,
                data,
            );
        },
        {
            onSuccess: () => {
                isCreatingActions.close();
                mutate();
            },
        },
    );
    const deleteSectionAction = useAsyncState(
        async (probe_id: UUID) =>
            await api.v3_report_delete_section(
                props.project_id,
                props.report_id,
                probe_id,
            ),
        {
            onSuccess: (report) =>
                mutate((cache) => (cache ? [report, cache[1]] : [report, []])),
        },
    );

    return (
        <>
            <Headline level={2} highlighted className="basis-full p-10">
                {info.info.title ?? "Unknown Report"}
            </Headline>
            {findings.map((f) => (
                <div key={f.id} className="bg-muted flex">
                    <div className="bg-background max-w-4xl shrink-0 basis-4xl overflow-hidden px-10 py-4">
                        <ReportSection finding={createAsyncFindingGroup(f)} />
                    </div>
                    <div className="grow border-l">
                        <div className="sticky top-0 space-y-4 border-t p-6">
                            <AsyncButton
                                variant="destructive"
                                onClick={() =>
                                    deleteSectionAction.submit(f.probe.id)
                                }
                                loading={deleteSectionAction.isSubmitting}
                            >
                                Delete Section
                            </AsyncButton>
                            <ProbeDetails probe={f.probe} className="text-xs" />
                        </div>
                    </div>
                </div>
            ))}
            <div className="basis-full p-10">
                {!isCreating ? (
                    <Button onClick={isCreatingActions.open}>
                        Add section
                    </Button>
                ) : (
                    <CreateSection
                        onCreate={addSectionAction.submit}
                        onCancel={isCreatingActions.close}
                    />
                )}
            </div>
        </>
    );
};

export const V3ReportRoute = () => {
    const { project_id, report_id } = useParams<{
        project_id: string;
        report_id: string;
    }>();

    if (
        project_id === undefined ||
        report_id === undefined ||
        !validateUUID(report_id) ||
        !validateUUID(project_id)
    ) {
        return <NotFoundRoute />;
    }

    return (
        <div className="relative grow overflow-scroll">
            <ReportImpl project_id={project_id} report_id={report_id} />
        </div>
    );
};
