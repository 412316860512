import { HTMLAttributes, ReactNode, useEffect, useRef } from "react";

import { APActionType, ContextStatus, DiscoverPanelStatus } from "@/api/types";
import { ActionHistory } from "@/components/action-panel/action-history";
import { MessageForm } from "@/components/action-panel/message-form";
import { StatusIndicator } from "@/components/action-panel/status-indicator";
import { ErrorAlert } from "@/components/error-alert";
import { useActionsContext } from "@/hooks/use-actions-context";
import { useContextContext } from "@/hooks/use-context-context";
import { useNetwork } from "@/hooks/use-network";

interface Props extends HTMLAttributes<HTMLDivElement> {
    header?: ReactNode;
    disableInquiry?: boolean;
}

export const ActionPanel = ({
    header,
    disableInquiry = false,
    ...props
}: Props) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    const isOnline = useNetwork();
    const status = useActionsContext((s) => s.status);
    const contextStatus = useContextContext((s) => s.status);
    const conversationID = useActionsContext((s) => s.conversationID);
    const sendAction = useActionsContext((s) => s.send);
    const isActionPanelOpen = useActionsContext((s) => s.isActionPanelOpen);
    useEffect(() => {
        if (isActionPanelOpen) {
            ref.current?.focus();
        }
    }, [isActionPanelOpen]);

    return (
        <div {...props}>
            {header}
            {contextStatus === ContextStatus.FAILED ? (
                <ErrorAlert
                    message="There was an error loading Chat"
                    className="m-4 w-auto"
                />
            ) : conversationID ? (
                <ActionHistory
                    className="grow"
                    disableInquiry={disableInquiry}
                />
            ) : (
                <div className="grow" />
            )}
            {disableInquiry === false && (
                <MessageForm
                    ref={ref}
                    onMessage={(message: string) =>
                        sendAction(APActionType.chat_message, { message })
                    }
                    disabled={
                        !isOnline ||
                        contextStatus < ContextStatus.LOADED ||
                        status > DiscoverPanelStatus.IDLE
                    }
                    leftContent={<StatusIndicator />}
                />
            )}
        </div>
    );
};
