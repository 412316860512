import { HTMLAttributes } from "react";

import {
    DocumentInfo,
    FindingContentType,
    FindingGroupInfo,
    FindingGroupType,
    GenericFindingContent,
    ProbeType,
} from "@/api/types";
import { CellState } from "@/components/analyze/cell-state";
import { Collapsible } from "@/components/document-table/condensed/collapsible";
import { CollapsibleList } from "@/components/document-table/condensed/collapsible-list";
import { CompactFinding } from "@/components/document-table/condensed/compact-finding";
import { BooleanFinding } from "@/components/document-table/condensed/finding-content/boolean";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { useFeatureFlagBool } from "@/hooks/use-feature-flag";
import { cn } from "@/lib/utils";
import { firstX } from "@/utils/collection";
import { nonNull } from "@/utils/fn";

interface Props extends HTMLAttributes<HTMLElement> {
    documentInfo?: DocumentInfo;
    groupInfo: FindingGroupInfo;
    findings: GenericFindingContent[];
}

export const CondensedFindingGroup = ({
    documentInfo,
    groupInfo,
    findings,
    className,
    ...props
}: Props) => {
    const forceBooleanFindingToFalse = useFeatureFlagBool(
        FeatureFlagBool.FORCE_BOOLEAN_FINDING_TO_FALSE,
    );
    // filter to short text from executive summary and map to list item conten type
    const filtered_findings =
        groupInfo.type === FindingGroupType.executive_summary
            ? findings
                  .filter(
                      (f) => f.content_type !== FindingContentType.long_text,
                  )
                  .map(
                      (f) =>
                          ({
                              ...f,
                              content_type: FindingContentType.list_item,
                          }) as GenericFindingContent,
                  )
                  .filter(nonNull)
            : findings;

    if (
        forceBooleanFindingToFalse &&
        groupInfo.type === FindingGroupType.user_defined_text &&
        groupInfo.probe?.type === ProbeType.boolean &&
        filtered_findings.length === 0
    ) {
        return <BooleanFinding value={false} />;
    }

    if (filtered_findings.length === 0) {
        return <CellState>No Findings</CellState>;
    }

    const content_type = firstX(filtered_findings).content_type;
    const children = filtered_findings.flatMap((finding) => (
        <CompactFinding
            key={finding.id}
            finding={finding}
            documentID={documentInfo?.id}
        />
    ));
    switch (content_type) {
        case FindingContentType.list_item:
            return (
                <CollapsibleList
                    className={cn("space-y-1", className)}
                    data-bw-actions
                    data-action-context={
                        documentInfo ? `document:${documentInfo.id}` : undefined
                    }
                    {...props}
                >
                    {children}
                </CollapsibleList>
            );
        case FindingContentType.boolean:
            return (
                <div className={className} {...props}>
                    {children}
                </div>
            );
        case FindingContentType.titled_list:
        case FindingContentType.full_text:
        case FindingContentType.long_text:
        case FindingContentType.numerical:
        case FindingContentType.numerical_full_text:
        case FindingContentType.short_text:
        case FindingContentType.titled_long_text:
        case FindingContentType.dated_long_text:
        case FindingContentType.unstructured:
        case FindingContentType.table:
            return (
                <Collapsible
                    className={cn("flex flex-col", className)}
                    data-bw-actions
                    data-action-context={
                        documentInfo ? `document:${documentInfo.id}` : undefined
                    }
                    {...props}
                >
                    {children}
                </Collapsible>
            );
        default:
            return content_type satisfies never;
    }
};
