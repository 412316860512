import { CircleAlertIcon } from "lucide-react";
import { PropsWithChildren, ReactNode } from "react";
import useSWRImmutable from "swr/immutable";
import { useShallow } from "zustand/react/shallow";

import { HttpStatusCode } from "@/api/http-status-codes";
import { HttpError } from "@/api/rest";
import {
    AsyncFindingGroup,
    DocumentStatus,
    FindingGroupStatus,
    FindingGroupType,
    UUID,
} from "@/api/types";
import { IncomingWebsocketEventType } from "@/api/ws/websocket-types";
import { DebugID } from "@/components/debug/debug-id";
import { Document } from "@/components/document/document";
import {
    DocumentFindingGroupContent,
    DocumentFindingSkeleton,
} from "@/components/document/finding-content";
import { useApi } from "@/hooks/use-api";
import { useGridView } from "@/hooks/use-grid-view-context";
import { useWebsocket } from "@/hooks/use-websocket";
import { AsyncState } from "@/utils/async-value";
import { mapMaybeGet } from "@/utils/es6-map";
import {
    createAsyncFindingGroup,
    getFindingGroup,
} from "@/utils/finding-group";

type Props = {
    documentID?: UUID;
};

export const DocumentContainer = (props: Props) => {
    const info = useGridView((s) => mapMaybeGet(s.documents, props.documentID));
    const executiveSummary = useGridView(
        useShallow((s) =>
            Array.from(
                mapMaybeGet(
                    s.documents_to_finding_groups,
                    props.documentID,
                )?.values() ?? [],
            )
                .map((id) => s.finding_groups.get(id))
                .find((fg) => fg?.type === FindingGroupType.executive_summary),
        ),
    );
    if (info === undefined) {
        return <DocumentError>Document not found</DocumentError>;
    }

    switch (info.status) {
        case DocumentStatus.initial:
        case DocumentStatus.processing:
        case DocumentStatus.processing_completed:
        case DocumentStatus.post_processing:
            return (
                <Document info={info}>
                    <DebugID label="Document Status" id={info.status} block />
                    <DocumentFindingSkeleton />
                </Document>
            );
        case DocumentStatus.ready:
            return (
                <Document info={info}>
                    <DebugID label="Document Status" id={info.status} block />
                    {executiveSummary === undefined ? (
                        <EnsureExecutiveSummary
                            documentID={info.id}
                            fallback={<DocumentFindingSkeleton />}
                            error={
                                <DocumentError>
                                    Failed to load summary
                                </DocumentError>
                            }
                        >
                            {(fg) => (
                                <>
                                    <DebugID
                                        label="Component"
                                        id="EnsureExecutiveSummary"
                                    />
                                    <AsyncDocumentFindingGroupContent
                                        asyncFindingGroup={fg}
                                    />
                                </>
                            )}
                        </EnsureExecutiveSummary>
                    ) : (
                        <AsyncDocumentFindingGroupContent
                            asyncFindingGroup={executiveSummary}
                        />
                    )}
                </Document>
            );

        case DocumentStatus.failed:
        case DocumentStatus.skipped:
            return (
                <Document info={info}>
                    <DebugID label="Document Status" id={info.status} block />
                    <DocumentError>Failed to process document</DocumentError>
                </Document>
            );
        default:
            return info.status satisfies never;
    }
};

const isHttpErrorTooEarly = (err: unknown): boolean =>
    err instanceof HttpError && err.status === HttpStatusCode.TooEarly;

const EnsureExecutiveSummary = (props: {
    documentID: UUID;
    fallback?: ReactNode;
    error?: ReactNode;
    children?: (asyncFG: AsyncFindingGroup) => ReactNode;
}) => {
    const api = useApi();
    const { data, isLoading, error, mutate } = useSWRImmutable(
        [props.documentID, "document-executive-summary"],
        async ([documentID]) =>
            createAsyncFindingGroup(
                await api.fetch_executive_summary(documentID),
            ),
        { shouldRetryOnError: isHttpErrorTooEarly },
    );
    useWebsocket((ws) => {
        ws.onIf(
            IncomingWebsocketEventType.finding_group_created,
            ({ document_id, type }) =>
                document_id === props.documentID &&
                type === FindingGroupType.executive_summary,
            () => mutate(),
        );
        ws.onIf(
            IncomingWebsocketEventType.finding_group_updated,
            ({ document_id, type }) =>
                document_id === props.documentID &&
                type === FindingGroupType.executive_summary,
            () => mutate(),
        );
    });
    if (isLoading) {
        return props.fallback;
    }
    if (error) {
        if (isHttpErrorTooEarly(error)) {
            return props.fallback;
        }
        return props.error;
    }
    if (data === undefined) {
        return props.fallback;
    }
    return props.children?.(data);
};

const DocumentError = (props: PropsWithChildren) => (
    <div className="text-destructive flex flex-col items-center justify-center gap-4 py-10 font-bold">
        <CircleAlertIcon className="size-4" />
        {props.children}
    </div>
);

const AsyncDocumentFindingGroupContent = (props: {
    asyncFindingGroup: AsyncFindingGroup;
}) => {
    switch (props.asyncFindingGroup.status) {
        case FindingGroupStatus.processing:
            return (
                <>
                    <DebugID
                        label="Finding Group Status"
                        id={props.asyncFindingGroup.status}
                        block
                    />
                    <DocumentFindingSkeleton />
                </>
            );
        case FindingGroupStatus.completed:
            switch (props.asyncFindingGroup.findings.state) {
                case AsyncState.initial:
                case AsyncState.queued:
                case AsyncState.fetching:
                    return (
                        <>
                            <DebugID
                                label="Async Findings State"
                                id={
                                    AsyncState[
                                        props.asyncFindingGroup.findings.state
                                    ]
                                }
                                block
                            />
                            <DocumentFindingSkeleton />
                        </>
                    );
                case AsyncState.error:
                    return (
                        <>
                            <DebugID
                                label="Async Findings State"
                                id={
                                    AsyncState[
                                        props.asyncFindingGroup.findings.state
                                    ]
                                }
                                block
                            />
                            <DocumentError>Failed to load</DocumentError>
                        </>
                    );
                case AsyncState.success:
                    return (
                        <DocumentFindingGroupContent
                            findingGroup={getFindingGroup(
                                props.asyncFindingGroup,
                            )}
                        />
                    );
            }
        case FindingGroupStatus.failed:
        case FindingGroupStatus.cancelled:
            return (
                <>
                    <DebugID
                        label="Finding Group Status"
                        id={props.asyncFindingGroup.status}
                        block
                    />
                    <DocumentError>Failed to load</DocumentError>
                </>
            );
    }
};
