import {
    ChartGanttIcon,
    CheckIcon,
    CombineIcon,
    EditIcon,
    LayoutPanelTop,
    LetterTextIcon,
    PlusIcon,
} from "lucide-react";
import { PropsWithChildren, useState } from "react";

import { CreateSynthesisProbeSchema, ProbeBase } from "@/api/rest";
import { FindingGroupType, Probe, SynthesisType } from "@/api/types";
import { AsyncButton } from "@/components/async-button";
import { ProbeTypeIcon } from "@/components/document-table/columns/probe-type-icon";
import { ColumnSheet } from "@/components/document-table/columns/sheet";
import { Headline } from "@/components/headline";
import { Button } from "@/components/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useAsyncState } from "@/hooks/use-async-state";
import { PageType, useColumnSheet } from "@/hooks/use-column-sheet";
import { cn } from "@/lib/utils";
import { emptyFunction } from "@/utils/empty-function";
import { plural } from "@/utils/string-helpers";

const BuilderStep = ({
    step,
    title,
    status = "pending",
    children,
}: PropsWithChildren<{
    step: number;
    title: string;
    status?: "pending" | "active" | "completed";
}>) => (
    <section
        className={cn(
            "space-y-2 overflow-hidden rounded transition-all",
            status === "active" && "bg-muted/50",
        )}
    >
        <Separator
            className={cn("h-1.5", {
                "bg-green-200 dark:bg-green-800": status === "completed",
                "bg-foreground": status === "active",
            })}
        />
        <header className="flex items-center gap-4 px-2">
            <div
                className={cn(
                    "bg-muted flex size-8 items-center justify-center rounded-full",
                    {
                        "bg-foreground text-background": status === "active",
                        "bg-green-200 text-green-800 dark:bg-green-800 dark:text-green-200":
                            status === "completed",
                    },
                )}
            >
                {status === "completed" ? (
                    <CheckIcon className="size-4" />
                ) : (
                    step
                )}
            </div>
            <Headline level={5}>{title}</Headline>
        </header>
        <div className="space-y-4 p-4">{children}</div>
    </section>
);

type Props = {
    onCreate: (data: CreateSynthesisProbeSchema) => Promise<void>;
    onCancel?: () => void;
};

export const CreateSection = (props: Props) => {
    const openPage = useColumnSheet((s) => s.openPage);
    const editProbe = useColumnSheet((s) => s.editProbe);
    const [analysis, setAnalysis] = useState<
        | { type: "existing"; probe: Probe }
        | { type: "new"; probe: ProbeBase }
        | null
    >(null);
    const [synthesisType, setSynthesisType] = useState<SynthesisType>(
        FindingGroupType.summary,
    );

    const handleEditAnalysis = () => {
        if (analysis?.type === "new") {
            editProbe({ id: "", ...analysis.probe } as Probe);
        } else if (analysis?.probe.owner_id) {
            openPage(PageType.starred);
        } else {
            openPage(PageType.presets);
        }
    };

    const handleSelect = (probe: Probe) =>
        setAnalysis({ type: "existing", probe });
    const handleCreate = (probe: ProbeBase) =>
        setAnalysis({ type: "new", probe });

    const action = useAsyncState(async () => {
        if (analysis === null || synthesisType === null) {
            throw new Error("Analysis or Synthesis not selected");
        }
        return await props.onCreate({
            synthesis_type: synthesisType,
            name: "Synthesis Name",
            prompt: null,
            contexts: [
                analysis.type === "new" ? analysis.probe : analysis.probe.id,
            ],
        });
    });

    return (
        <>
            <div className="grid grid-cols-3 gap-x-6 gap-y-10 rounded border p-4 shadow-lg">
                <BuilderStep
                    step={1}
                    title={plural(20, "Document")}
                    status="completed"
                >
                    Brightwave will read every page of every document
                </BuilderStep>
                <BuilderStep
                    step={2}
                    title={analysis === null ? "Select Analysis" : "Analysis"}
                    status={analysis === null ? "active" : "completed"}
                >
                    {analysis === null ? (
                        <div className="grid gap-4">
                            <p>
                                Choose what data to extract from each document
                            </p>
                            <Button
                                variant="primary"
                                className="gap-2"
                                onClick={() => openPage(PageType.create)}
                            >
                                <PlusIcon className="size-4" />
                                Create Analysis
                            </Button>
                        </div>
                    ) : (
                        <div className="grid gap-4">
                            <p>
                                For each document, Brighwave will extract{" "}
                                <span className="flex items-center gap-2 font-medium">
                                    <ProbeTypeIcon type={analysis.probe.type} />
                                    {analysis.probe.name}
                                </span>
                            </p>
                            <Button
                                variant="ghost"
                                onClick={handleEditAnalysis}
                            >
                                <EditIcon className="size-4" /> Edit
                            </Button>
                        </div>
                    )}
                </BuilderStep>
                <BuilderStep
                    step={3}
                    title="Select Synthesis"
                    status={analysis === null ? "pending" : "active"}
                >
                    {analysis !== null && (
                        <>
                            <p>
                                Select how Brightwave synthesizes all
                                <span className="px-1 font-medium">
                                    {analysis.probe.name}
                                </span>
                                findings extracted from each document.
                            </p>
                            <Select
                                defaultValue={synthesisType}
                                onValueChange={(v) =>
                                    setSynthesisType(v as SynthesisType)
                                }
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select synthesis type" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem
                                        value={
                                            FindingGroupType.structured_summary
                                        }
                                    >
                                        <div className="flex items-center gap-2">
                                            <LayoutPanelTop className="size-4" />
                                            Structured Summary
                                        </div>
                                    </SelectItem>
                                    <SelectItem
                                        value={FindingGroupType.summary}
                                    >
                                        <div className="flex items-center gap-2">
                                            <LetterTextIcon className="size-4" />
                                            Summarize
                                        </div>
                                    </SelectItem>
                                    <SelectItem value={FindingGroupType.dedupe}>
                                        <div className="flex items-center gap-2">
                                            <CombineIcon className="size-4" />
                                            Deduplicate
                                        </div>
                                    </SelectItem>
                                    <SelectItem
                                        value={FindingGroupType.timeline}
                                    >
                                        <div className="flex items-center gap-2">
                                            <ChartGanttIcon className="size-4" />
                                            Create a timeline
                                        </div>
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </>
                    )}
                </BuilderStep>
                <div className="col-span-full col-start-1 flex justify-end gap-4">
                    {props.onCancel && (
                        <Button
                            variant="ghost-destructive"
                            onClick={props.onCancel}
                        >
                            Cancel
                        </Button>
                    )}
                    <AsyncButton
                        variant="primary"
                        size="lg"
                        className="rounded-full"
                        action={action}
                        disabled={(analysis ?? synthesisType) === null}
                    >
                        Create Section
                    </AsyncButton>
                </div>
            </div>

            <ColumnSheet
                addedProbeIDs={new Set()}
                onSelect={handleSelect}
                onCreate={handleCreate}
                onUpdate={(_, data) => handleCreate(data)}
                onStarChange={emptyFunction}
            />
        </>
    );
};
