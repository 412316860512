import { FindingContentType, GenericFindingContent } from "@/api/types";
import { StructuredTable } from "@/components/document-table/condensed/finding-content/structured-table";
import { FullstoryRedacted } from "@/components/fullstory-redacted";
import { Markdown } from "@/components/markdown";
import { Badge } from "@/components/ui/badge";
import { useDevMode } from "@/hooks/use-dev-mode";
import { capitalize, simpleHash } from "@/utils/string-helpers";

type Props = {
    finding: GenericFindingContent;
};

export const DetailedFinding = (props: Props) => {
    return (
        <FullstoryRedacted asChild>
            <DetailedFindingContent {...props} />
        </FullstoryRedacted>
    );
};

const DetailedFindingContent = ({ finding }: Props) => {
    const [devMode] = useDevMode();
    switch (finding.content_type) {
        case FindingContentType.numerical:
        case FindingContentType.boolean:
            return <span>{finding.text}</span>;
        case FindingContentType.long_text:
            return <Markdown>{finding.text}</Markdown>;
        case FindingContentType.short_text:
            return <p className="relative inline-block">{finding.text}</p>;
        case FindingContentType.full_text:
        case FindingContentType.titled_long_text:
        case FindingContentType.dated_long_text:
            return (
                <div className="group relative inline-block" role="button">
                    <p className="font-headline mr-2 font-semibold">
                        {finding.title}
                    </p>
                    <Markdown className="text-muted-foreground">
                        {finding.text}
                    </Markdown>
                </div>
            );
        case FindingContentType.titled_list:
            return (
                <div
                    className="group relative inline-block space-y-2"
                    role="button"
                >
                    <p className="font-headline mr-2 font-semibold">
                        {capitalize(finding.title)}
                    </p>
                    {finding.key_points.map((text, i) => (
                        <p
                            key={simpleHash(text + i)}
                            className="text-muted-foreground"
                        >
                            {text}
                        </p>
                    ))}
                </div>
            );
        case FindingContentType.list_item:
            return (
                <li className="flex grow items-center gap-1.5">
                    <div className="bg-foreground/35 h-[2px] w-2 shrink-0 rounded-full" />
                    <span>{finding.text}</span>
                </li>
            );
        case FindingContentType.table:
            return (
                <div className="space-y-4">
                    <p className="font-headline font-semibold empty:hidden">
                        {finding.title}
                    </p>
                    <p className="empty:hidden">{finding.short_text}</p>
                    <StructuredTable finding={finding} />
                </div>
            );
        case FindingContentType.numerical_full_text:
        case FindingContentType.unstructured:
            return devMode ? (
                <Badge variant="destructive">
                    unsupported type: {finding.content_type}
                </Badge>
            ) : null;
        default:
            if (devMode) {
                return (
                    <pre className="bg-muted rounded p-2 text-[9px]">
                        {JSON.stringify(finding, null, 2)}
                    </pre>
                );
            } else {
                return finding satisfies never;
            }
    }
};
