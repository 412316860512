import { ArrowRight } from "lucide-react";
import { use } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { ErrorAlert } from "@/components/error-alert";
import { LoginForm } from "@/components/forms/login";
import { Button } from "@/components/ui/button";
import { AppContext } from "@/context/app-context";
import { useAsyncState } from "@/hooks/use-async-state";
import { useConfig } from "@/hooks/use-config";
import { usePageTitle } from "@/hooks/use-page-title";

export const LoginRoute = () => {
    usePageTitle("Login");
    const { auth0 } = useConfig();
    const { api, login } = use(AppContext);
    const action = useAsyncState(
        async (email: string, password: string) =>
            await api.login_user(email, password),
        { onSuccess: login },
    );
    const [params] = useSearchParams();
    const loginError = params.get("error");
    // If the BE sends us a connection, it's basically telling us that we should force
    // the user to login with that connection
    const loginConnection = params.get("connection");
    const loginPath = loginConnection
        ? `/api/auth/logout?connection=${loginConnection}`
        : "/api/auth/login";

    return (
        <>
            <div className="space-y-4">
                <h2 className="font-headline text-center text-2xl font-semibold">
                    Welcome Back
                </h2>
                {!auth0 && (
                    <p className="text-center text-sm font-light">
                        Enter your email and password to access your account
                    </p>
                )}
            </div>
            {action.error && <ErrorAlert error={action.error} />}
            {loginError && <ErrorAlert message={loginError} />}

            {auth0 ? (
                <Button variant="primary" asChild>
                    <a href={loginPath}>
                        Login
                        <ArrowRight className="ml-2 size-4" />
                    </a>
                </Button>
            ) : (
                <>
                    <LoginForm action={action} />
                    <div className="text-center">
                        <Button variant="link" asChild>
                            <Link to="/forgot-password">Forgot password?</Link>
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
