import { ComponentPropsWithRef, HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

type Props = HTMLAttributes<HTMLSpanElement> & ComponentPropsWithRef<"span">;

export const CitationNumber = ({ ref, className, ...props }: Props) => (
    <span
        ref={ref}
        className={cn(
            "font-headline mx-1 rounded-sm bg-neutral-300 px-2 font-extrabold transition-colors select-none dark:bg-neutral-700",
            className,
        )}
        {...props}
    />
);
